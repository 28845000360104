import { MouseEvent, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button, Checkbox, Step } from 'semantic-ui-react';
import { GridViewTable } from '../../../components/GridView';
import { GridViewColumnDefinitions } from '../../../components/GridView/GridViewTable';
import styles from '../ProjectScanViewer.module.css';
import { ToasterProps } from '../../../components/ToastMessage';
import { useMutation } from '@apollo/client';
import { GQL_DELETE_RESOLUTION } from '../../Resolutions/Resolutions';
import { DeleteResolutionMutation } from '../../Resolutions/__generated__/DeleteResolutionMutation';
import { ProjVulnDetailModal } from './ProjVulnDetailModal';
import {
  ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries,
  ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries_vulnerabilities,
  ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities,
  ProjVulnsQueryWithSummaries_projects_resolutions,
} from '../__generated__/ProjVulnsQueryWithSummaries';
import { ProjLangVulnExceptionModal } from './ProjLangVulnExceptionModal';

type Props = {
  resolutions: ProjVulnsQueryWithSummaries_projects_resolutions[] | undefined | null;
  vulnerabilities:
    | (ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities | null)[]
    | null;
  codeScanvulns:
    | (ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries | null)[]
    | null;
  projectName: string;
  pullRequest: string;
  repo: string;
  toasterProps: ToasterProps;
  messageFunction: (summary: string, message: string) => void;
  refetchFunction: () => void;
};

const getVulnsFromVuln = (
  vuln: ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities
): ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities[] => {
  let results: ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities[] = [
    vuln,
  ];
  return results;
};

const getPackageNameFromVuln = (
  vuln: ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities
): string => {
  return vuln?.packageName ?? '';
};

const filterSummaryVulns = (
  vulns: (
    | ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries
    | null
    | undefined
  )[],
  filterOutLowSeverities: boolean
) => {
  return vulns.filter(
    (
      item:
        | ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries
        | null
        | undefined
    ) => {
      if (
        filterOutLowSeverities &&
        (item?.severity === 'LOW' || item?.severity === 'MEDIUM' || item?.severity === 'UNKNOWN')
      ) {
        return false;
      }
      return item?.status === 'OPEN';
    }
  );
};

const filterAllVulns = (
  vulns: (
    | ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities
    | null
    | undefined
  )[],
  filterOutLowSeverities: boolean
) => {
  return vulns.filter(
    (
      item:
        | ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities
        | null
        | undefined
    ) => {
      if (
        filterOutLowSeverities &&
        (item?.severity === 'LOW' || item?.severity === 'MEDIUM' || item?.severity === 'UNKNOWN')
      ) {
        return false;
      }
      return item?.status === 'OPEN';
    }
  );
};

const getScopeDisplayText = (key: string, id: string) => {
  const parts = id.split(':');
  if (key === 'CLASS') {
    if (parts[1] === 'CLASS') {
      return 'Entire Base Image';
    }
    return key;
  }
  if (key === 'VULN') {
    if (parts[1]) {
      return parts[1];
    }
    return 'Individual Vulnerability';
  }
};

const statusMap: { [status: string]: string } = {
  FIX_CAUSES_PROBLEMS: 'Fix Requires Substancial Refactoring',
  VULN_NOT_APPLICABLE: "Vulnerability Doesn't Apply",
  OTHER: 'Other',
};

const getStatusDisplayText = (status: string) => {
  var resultMsg = statusMap[status];
  return resultMsg ?? status;
};

const getVulnsForSummary = (
  summary: ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries
): (ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries_vulnerabilities | null)[] => {
  return summary.vulnerabilities;
};

const getPackageName = (
  summary: ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries
): string => {
  return summary.package;
};

export function ProjectScanVulns({
  resolutions,
  toasterProps: props,
  projectName,
  codeScanvulns,
  vulnerabilities,
  pullRequest,
  repo,
  messageFunction,
  refetchFunction,
}: Props) {
  const SHOW_SUMMARY_VULNS: number = 1;
  const SHOW_ALL_VULNS: number = 2;
  const SHOW_RESOLUTIONS: number = 3;

  const [displayState, setDisplayState] = useState<number>(SHOW_SUMMARY_VULNS);
  const [filterOutLowSeverities, setFilterOutLowSeverities] = useState<boolean>(true);

  let showSummaryVulns = (event?: MouseEvent) => {
    setDisplayState(SHOW_SUMMARY_VULNS);
  };

  let showAllVulns = (event?: MouseEvent) => {
    setDisplayState(SHOW_ALL_VULNS);
  };

  let showResolutions = (event?: MouseEvent) => {
    setDisplayState(SHOW_RESOLUTIONS);
  };

  const handleSeverityFilterToggle = (isChecked: boolean | undefined) => {
    console.log('In Severity Toggle:' + isChecked);
    setFilterOutLowSeverities(isChecked || false);
  };

  const summaryTableColumns: GridViewColumnDefinitions<
    | ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries
    | null
    | undefined
  >[] = [
    {
      id: uuid(),
      footer: (props) => props.column.id,
      enableSorting: true,
      columns: [
        {
          id: 'severity',
          accessorFn: (row) => row?.severity,
          header: 'Severity',
        },
        {
          id: 'package',
          accessorFn: (row) => row?.package,
          header: 'Package',
        },
        {
          id: 'installedVersion',
          accessorFn: (row) => row?.installedVersion,
          header: 'Installed Version',
        },
        {
          id: 'fixedVersion',
          accessorFn: (row) => row?.fixedVersion,
          header: 'Fixed Version',
        },
        {
          id: 'target',
          accessorFn: (row) => row?.target,
          header: 'Target',
        },
        {
          id: 'details',
          accessorFn: (row) => row,
          header: '',
          cell: (cell) => {
            return (
              <ProjVulnDetailModal
                packageName={getPackageName(cell.getValue())}
                vulns={getVulnsForSummary(cell.getValue())}
              ></ProjVulnDetailModal>
            );
          },
        },
        {
          id: 'exception',
          accessorFn: (row) => row,
          header: '',
          cell: (cell) => {
            return (
              <ProjLangVulnExceptionModal
                onExceptionAdded={messageFunction}
                vulnerabilities={getVulnsForSummary(cell.getValue())}
                packageName={getPackageName(cell.getValue())}
                projectName={projectName}
              />
            );
          },
        },
      ],
    },
  ];

  const allVulnTableColumns: GridViewColumnDefinitions<
    ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities | null | undefined
  >[] = [
    {
      id: uuid(),
      footer: (props) => props.column.id,
      enableSorting: true,
      columns: [
        {
          id: 'status',
          accessorFn: (row) => row?.status,
          header: 'Status',
        },
        {
          id: 'severity',
          accessorFn: (row) => row?.severity,
          header: 'Severity',
        },
        {
          id: 'package',
          accessorFn: (row) => row?.packageName,
          header: 'Package Name',
        },
        {
          id: 'vulnID',
          accessorFn: (row) => row?.vulnerabilityID,
          header: 'ID',
        },
        {
          id: 'title',
          accessorFn: (row) => row?.title,
          header: 'Title',
        },
        {
          id: 'target',
          accessorFn: (row) => row?.target,
          header: 'Target',
        },
        {
          id: 'installedVersion',
          accessorFn: (row) => row?.installedVersion,
          header: 'Installed Version',
        },
        {
          id: 'fixedVersion',
          accessorFn: (row) => row?.fixedVersion,
          header: 'Fixed Version',
        },
        {
          id: 'details',
          accessorFn: (row) => row,
          header: '',
          cell: (cell) => {
            return (
              <ProjVulnDetailModal
                packageName={getPackageNameFromVuln(cell.getValue())}
                vulns={getVulnsFromVuln(cell.getValue())}
              ></ProjVulnDetailModal>
            );
          },
        },
        {
          id: 'exception',
          accessorFn: (row) => row,
          header: '',
          cell: (cell) => {
            return (
              <ProjLangVulnExceptionModal
                onExceptionAdded={messageFunction}
                vulnerabilities={getVulnsFromVuln(cell.getValue())}
                packageName={getPackageNameFromVuln(cell.getValue())}
                projectName={projectName}
              />
            );
          },
        },
      ],
    },
  ];

  const [deleteResolutionMutation] = useMutation<DeleteResolutionMutation>(GQL_DELETE_RESOLUTION);

  const resolutionTableColumns: GridViewColumnDefinitions<ProjVulnsQueryWithSummaries_projects_resolutions>[] =
    [
      {
        id: uuid(),
        footer: (props) => props.column.id,
        columns: [
          {
            id: 'scope',
            accessorFn: (row) => getScopeDisplayText(row.scope, row.id),
            header: 'Scope',
          },
          {
            id: 'owner',
            accessorFn: (row) => row.owner,
            header: 'Owner',
          },
          {
            id: 'status',
            accessorFn: (row) => getStatusDisplayText(row.status),
            header: 'Status',
          },
          {
            id: 'note',
            accessorFn: (row) => row.note,
            header: 'Note',
          },
          {
            id: 'expiresAt',
            accessorFn: (row) => {
              if (row.expiresAt) {
                return new Date(row.expiresAt).toLocaleString();
              }
            },
            header: 'Expiration',
          },
          {
            id: 'created',
            accessorFn: (row) => {
              if (row.expiresAt) {
                return new Date(row.acceptedAt).toLocaleString();
              }
            },
            header: 'Created',
          },
          {
            id: 'author',
            accessorFn: (row) => row.author,
            header: 'Author',
          },
          {
            id: 'deleteResolution',
            size: 20,
            accessorFn: (row) => row.id,
            cell: (cell) => {
              return (
                <Button
                  onClick={async () => {
                    try {
                      await deleteResolutionMutation({
                        variables: {
                          id: cell.getValue(),
                        },
                      });
                      refetchFunction();
                    } catch (e: any) {
                      props.toaster.error('Error Deleting Resolution', e.message);
                      console.log(e.message);
                      refetchFunction();
                      return;
                    }
                  }}
                >
                  Delete Resolution
                </Button>
              );
            },
            header: '',
          },
        ],
      },
    ];

  return (
    <div>
      <Step.Group attached="bottom">
        <Step
          active={displayState === SHOW_SUMMARY_VULNS}
          icon="code"
          link
          onClick={showSummaryVulns}
          title="Package Vulnerabilies"
          description="All Vulnerable Packages"
        />
        <Step
          active={displayState === SHOW_ALL_VULNS}
          icon="code"
          link
          onClick={showAllVulns}
          title="All Vulnerabilities"
          description="All Vulnerabilities Shown"
        />
        <Step
          active={displayState === SHOW_RESOLUTIONS}
          icon="file image"
          link
          onClick={showResolutions}
          title="Suppressed Vulnerabilities"
          description="Some vulnerability fixes have been deferred."
        />
      </Step.Group>
      <div className={styles.imageVersionText}>
        <Checkbox
          toggle
          label="Show only Critical and High Vulns"
          checked={filterOutLowSeverities}
          onChange={(e, data) => handleSeverityFilterToggle(data.checked)}
        />
      </div>
      {displayState === SHOW_SUMMARY_VULNS && (
        <GridViewTable
          style={{ width: '100%' }}
          striped
          sortable
          pageSize={200}
          data={filterSummaryVulns(codeScanvulns ?? [], filterOutLowSeverities)}
          columns={summaryTableColumns}
        />
      )}
      {displayState === SHOW_ALL_VULNS && (
        <GridViewTable
          style={{ width: '100%' }}
          striped
          sortable
          pageSize={200}
          data={filterAllVulns(vulnerabilities ?? [], filterOutLowSeverities)}
          columns={allVulnTableColumns}
        />
      )}
      {displayState === SHOW_RESOLUTIONS && (
        <GridViewTable
          style={{ width: '100%' }}
          striped
          sortable
          pageSize={200}
          data={resolutions ?? []}
          columns={resolutionTableColumns}
        />
      )}
    </div>
  );
}
