import { gql, useQuery } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Header, Modal } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import styles from './PullRequestViewer.module.css';
import {
  PullRequestDetailsQuery,
  PullRequestDetailsQueryVariables,
  PullRequestDetailsQuery_projects_pullRequests,
} from './__generated__/PullRequestDetailsQuery';
import {
  GridViewColumnDefinitions,
  GridViewTable,
} from '../../../components/GridView/GridViewTable';

const GQL_GET_IMAGE_VERSION_DETAIL = gql`
  query PullRequestDetailsQuery($projectName: ID) {
    projects(filter: { projectName: $projectName }) {
      applicationName
      repository
      pullRequests {
        pullRequestId
        latestScan {
          version
          scanTime
          scanSummary {
            openStats {
              totalVulns
              criticalVulns
              highVulns
              mediumVulns
              lowVulns
            }
          }
        }
      }
    }
  }
`;

export function PullRequestViewer() {
  const [searchParams] = useSearchParams();
  const projectName = searchParams.get('projectName');

  const { loading, error, data } = useQuery<
    PullRequestDetailsQuery,
    PullRequestDetailsQueryVariables
  >(GQL_GET_IMAGE_VERSION_DETAIL, {
    variables: { projectName: projectName + '' },
  });

  const closeModal = () => {
    console.log('In closeModal');
  };

  const navigate = useNavigate();

  const goToVersionDetails = (targetPullRequest: string) =>
    navigate(
      '/projects/pr/scan?projectName=' + projectName + '&pullRequestId=' + targetPullRequest
    );

  const getHeaderText = () => {
    if (!projectName) return 'Project Not Found';
    return 'PRs for Project: ' + projectName;
  };

  const getScanTimeString = (scanTime: any | undefined) => {
    console.log('scanDate is:' + scanTime);
    if (scanTime) {
      let scanDate = new Date(scanTime);
      return scanDate?.toDateString() + ', ' + scanDate?.toLocaleTimeString();
    } else return '';
  };

  const tableColumns: GridViewColumnDefinitions<PullRequestDetailsQuery_projects_pullRequests | null>[] =
    [
      {
        id: uuid(),
        footer: (props) => props.column.id,
        columns: [
          {
            id: 'id',
            accessorFn: (row) => row?.pullRequestId,
            header: 'ID',
          },
          {
            id: 'scanDate',
            accessorFn: (row) => getScanTimeString(row?.latestScan?.scanTime),
            header: 'Last Scanned',
          },
          {
            id: 'totalVulns',
            accessorFn: (row) => row?.latestScan?.scanSummary?.openStats?.totalVulns,
            header: '# Open Vulnerabilities',
          },
          {
            id: 'criticals',
            accessorFn: (row) => row?.latestScan?.scanSummary?.openStats?.criticalVulns,
            header: 'Criticals',
          },
          {
            id: 'highs',
            accessorFn: (row) => row?.latestScan?.scanSummary?.openStats?.highVulns,
            header: 'Highs',
          },
          {
            id: 'mediums',
            accessorFn: (row) => row?.latestScan?.scanSummary?.openStats?.mediumVulns,
            header: 'Mediums',
          },
          {
            id: 'lows',
            accessorFn: (row) => row?.latestScan?.scanSummary?.openStats?.lowVulns,
            header: 'Lows',
          },
          {
            id: 'viewDetails',
            size: 20,
            accessorFn: (row) => row?.pullRequestId,
            cell: (cell) => {
              return (
                <Button
                  onClick={() => {
                    goToVersionDetails(cell.getValue());
                  }}
                >
                  Pull Request Detail
                </Button>
              );
            },
            header: '',
          },
        ],
      },
    ];

  if (loading) {
    return <div />;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <Modal open onClose={closeModal}>
      <Modal.Header>Pull Requests</Modal.Header>
      <Modal.Content>
        <div className={styles.resolutions}>
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{ flex: 1, background: 'white', padding: '2rem' }}>
              <div className={styles.headingWrapper}>
                <Header as="h2">{getHeaderText()}</Header>
                <Button onClick={() => navigate('/projects/')}>Return to Projects</Button>
              </div>
              {(data?.projects ? data.projects.length : 0) > 0 && (
                <GridViewTable
                  style={{ width: '100%' }}
                  striped
                  sortable
                  pageSize={20}
                  data={data?.projects[0].pullRequests ?? []}
                  columns={tableColumns}
                />
              )}
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
