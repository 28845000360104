import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export const gqlCredentialStore = {
  setToken: (token: string) => {
    window.localStorage.setItem('token', token);
  },
  getToken: (): string | null => {
    return window.localStorage.getItem('token');
  },
  clearToken: () => {
    window.localStorage.removeItem('token');
  },
};

const httpLink = createHttpLink({
  uri: '/query',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = gqlCredentialStore.getToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: 'same-origin',
});
