import { gql, useMutation, useQuery } from '@apollo/client';
import Lottie from 'lottie-react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormField,
  Icon,
  Input,
  Message,
  Modal,
  Select,
  TextArea,
} from 'semantic-ui-react';
import { GQL_GET_VIEWER } from '../../../layouts/StandardAppLayout/components/AppHeader/AppHeader';
import { ViewerQuery } from '../../../layouts/StandardAppLayout/components/AppHeader/__generated__/ViewerQuery';
import styles from './AddResolution.module.css';
import animationData from './animation.json';
import {
  CreateResolutionMutation,
  CreateResolutionMutationVariables,
} from './__generated__/CreateResolutionMutation';

const GQL_ADD_RESOLUTION = gql`
  mutation CreateResolutionMutation(
    $imageName: String!
    $reference: String!
    $owner: String!
    $status: String!
    $note: String!
    $scope: String!
  ) {
    createResolution(
      input: {
        imageName: $imageName
        reference: $reference
        owner: $owner
        status: $status
        note: $note
        scope: $scope
      }
    ) {
      id
      author
      owner
      status
      scope
    }
  }
`;

type Props = {
  artifactKey?: string | null;
  //defaultUser: string;
  onResolutionAdded: (message?: string) => void;
};

export function AddResolution({ artifactKey: artifactKey, onResolutionAdded }: Props) {
  console.log('In add resolution, got imageName:' + artifactKey);

  const { data: viewerData, loading: viewerLoading } = useQuery<ViewerQuery>(GQL_GET_VIEWER);
  const viewerEmail = viewerLoading ? '' : `${viewerData?.viewer?.email}`;

  const referenceRef = useRef<Input | null>(null);
  const artifactKeyRef = useRef<Input | null>(null);
  const noteRef = useRef<TextArea | null>(null);
  const ownerRef = useRef<Input | null>(null);
  const scopeRef = useRef<Input | null>(null);
  const statusRef = useRef<Input | null>(null);

  const [reference, setReference] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [owner, setOwner] = useState<string>(viewerEmail);
  const [scope, setScope] = useState<string>('VULN');
  const [status, setStatus] = useState<string>('Accepted');
  const [artifactKeyInput, setArtifactKeyInput] = useState<string>(artifactKey ?? '');

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [mutation, mutationContext] = useMutation<
    CreateResolutionMutation,
    CreateResolutionMutationVariables
  >(GQL_ADD_RESOLUTION);

  const closeModal = () => {
    if (mutationContext.loading) {
      console.log('Modal was loading');
      return;
    }
    navigate(-1);
  };

  const statusOptions = [
    { key: 'AcceptedKey', value: 'Accepted', text: 'Accepted' },
    { key: 'NotApplicableKey', value: 'Not Applicable', text: 'Not Applicable' },
  ];

  const scopeOptions = [
    { key: 'ImageKey', value: 'IMAGE', text: 'Entire Image' },
    { key: 'ClassKey', value: 'CLASS', text: 'Class of Vulnerabilities' },
    { key: 'VulnKey', value: 'VULN', text: 'Individual Vulnerability' },
  ];

  return (
    <Modal open onClose={closeModal}>
      <Modal.Header>Add Your Resolution Item</Modal.Header>
      <Modal.Content>
        <div className={styles.messagePlaceholder}>
          {errorMessage && (
            <Message negative className={styles.errorMessage}>
              <Icon name="bug" size="big" className="flat" />
              {errorMessage}
            </Message>
          )}
        </div>
        <div className={styles.modalContent}>
          <Lottie style={{ width: '40rem' }} animationData={animationData} />
          <form className={styles.form}>
            <FormField className={styles.formField}>
              <label>Artifact Name</label>
              <Input
                className={styles.input}
                ref={artifactKeyRef}
                defaultValue={artifactKeyInput}
                disabled={!!artifactKey}
                onChange={(evt) => setArtifactKeyInput(evt.target.value)}
              />
            </FormField>
            <hr />
            <FormField className={styles.formField}>
              <label>Scope</label>
              <Select
                ref={scopeRef}
                options={scopeOptions}
                defaultValue={scope}
                onChange={(evt, data) => setScope(String(data.value))}
              />
            </FormField>
            <FormField className={styles.formField}>
              <label>Status</label>
              <Select
                ref={statusRef}
                options={statusOptions}
                defaultValue={status}
                onChange={(evt, data) => setStatus(String(data.value))}
              />
            </FormField>
            <FormField className={styles.formField}>
              <label>Owner</label>
              <Input
                className={styles.input}
                ref={ownerRef}
                defaultValue={owner}
                onChange={(evt) => setOwner(evt.target.value)}
              />
            </FormField>
            <FormField className={styles.formField}>
              <label>Note for Resolution</label>
              <TextArea
                className={styles.textArea}
                ref={noteRef}
                defaultValue={note}
                onChange={(evt) => setNote(evt.target.value)}
              />
            </FormField>
            <hr />
            <FormField className={styles.formField}>
              <label>Vuln ID / Class Name</label>
              <Input
                className={styles.input}
                ref={referenceRef}
                defaultValue={reference}
                onChange={(evt) => setReference(evt.target.value)}
              />
            </FormField>
          </form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="grey" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            console.log('in onclick');
            try {
              await mutation({
                variables: {
                  reference: reference,
                  imageName: artifactKeyInput,
                  note: note,
                  owner: owner,
                  scope: scope,
                  status: status,
                },
              });
            } catch (e: any) {
              setErrorMessage(e.message);
              console.log(e.message);
              return;
            }
            await onResolutionAdded();
            closeModal();
          }}
          content="Save Resolution Item"
          labelPosition="right"
          icon="checkmark"
          positive
          disabled={note.trim().length === 0}
        />
      </Modal.Actions>
    </Modal>
  );
}
