import { gql, useQuery } from '@apollo/client';
import { Header } from 'semantic-ui-react';

import styles from './ScanViewer.module.css';
import { workQueueScans } from './__generated__/workQueueScans';
import { workQueueScansVariables } from './__generated__/workQueueScans';
import ScanStatusStepGroup from './components/ScanStatusStepGroup';
import {
  checkInProgressScans,
  checkInProgressScansVariables,
} from './__generated__/checkInProgressScans';
import { checkDoneScans, checkDoneScansVariables } from './__generated__/checkDoneScans';
import { useEffect } from 'react';

const GQL_GET_PENDING_SCANS = gql`
  query workQueueScans($imageName: ID, $version: ID) {
    workQueueScans(filter: { imageName: $imageName, imageVersion: $version }) {
      artifactName
      imageName
      version
      repository
      severities
      imageCount
    }
  }
`;

const GQL_GET_IN_PROGRESS_SCANS = gql`
  query checkInProgressScans($imageName: ID, $version: ID) {
    inProgressScans(filter: { imageName: $imageName, imageVersion: $version }) {
      artifactName
      imageName
      version
      repository
      severities
      imageCount
    }
  }
`;

const GQL_CHECK_SCAN_COMPLETED = gql`
  query checkDoneScans($imageName: ID, $version: ID) {
    images(filter: { imageName: $imageName, imageVersion: $version }) {
      imageName
      repository
      versions {
        version
        scanSummary {
          scanHistory {
            scanTime
          }
        }
      }
    }
  }
`;

type Props = {
  imageName: string;
  version: string;
  scanStatusCallback?: (status: string) => void;
  afterScanTime?: Date;
};

export function ScanViewer({ imageName, version, scanStatusCallback, afterScanTime }: Props) {
  let scanStatus: string = 'not_found';
  let scanName: string = imageName + ':' + version;

  let getHeaderText = () => 'Scan Status: ' + scanName;

  const {
    loading: pendingLoading,
    error: pendingError,
    data: pendingData,
    refetch: pendingRefetch,
  } = useQuery<workQueueScans, workQueueScansVariables>(GQL_GET_PENDING_SCANS, {
    variables: { imageName: imageName, version: version },
  });

  const {
    loading: inProgressLoading,
    error: inProgressError,
    data: inProgressData,
    refetch: inProgressRefetch,
  } = useQuery<checkInProgressScans, checkInProgressScansVariables>(GQL_GET_IN_PROGRESS_SCANS, {
    variables: { imageName: imageName, version: version },
  });

  const {
    loading: completedLoading,
    error: completedError,
    data: completedData,
    refetch: completedRefetch,
  } = useQuery<checkDoneScans, checkDoneScansVariables>(GQL_CHECK_SCAN_COMPLETED, {
    variables: { imageName: imageName, version: version },
  });

  const getLastScanTime = (): Date | undefined => {
    //    console.log('ScanViewer: getLastScanTime: Begin');
    if (
      !!completedData &&
      !!completedData.images &&
      completedData.images.length > 0 &&
      !!completedData?.images[0].versions &&
      completedData?.images[0].versions.length > 0 &&
      !!completedData?.images[0].versions[0]?.scanSummary
    ) {
      let mostRecentScanDate: Date | undefined;
      let history = completedData?.images[0].versions[0]?.scanSummary?.scanHistory;
      history?.map((histItem) => {
        let checkDate = new Date(histItem?.scanTime);
        if (!mostRecentScanDate || checkDate > mostRecentScanDate) {
          mostRecentScanDate = checkDate;
        }
      });
      // console.log('ScanViewer: getLastScanTime: returning ' + mostRecentScanDate);
      return mostRecentScanDate;
    }
    // console.log('ScanViewer: getLastScanTime: returning');
  };

  const getYearsAgo = (y: number) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() - y);
    return date;
  };

  const isCompleted = () => {
    console.log('ScanViewer: isCompleted: Begin');
    if ((completedData?.images.length ?? 0) > 0) {
      console.log('ScanViewer: isCompleted: completedData.images.length > 0');
      let latestScanTime: Date = getLastScanTime() ?? getYearsAgo(1); // if the previous check is true, getLastScanTime() should succeed?
      let since = afterScanTime ?? getYearsAgo(2); // if afterScanTime is not set, always succeed.
      //let oneMinBeforeCutoff = new Date(since.getTime() - 1 * 60000); // give browser up to 1 minute of lag in case time not in sync with server
      let oneMinBeforeCutoff = new Date(since.getTime());
      console.log('ScanViewer: isCompleted: latest Scan Time is ' + latestScanTime.toISOString());
      console.log('ScanViewer: isCompleted: since is ' + since.toISOString());
      if (latestScanTime > oneMinBeforeCutoff) {
        console.log('ScanViewer: isCompleted: returning true');
        return true;
      }
    }
    console.log('ScanViewer: isCompleted: returning false');
    return false;
  };

  const getScanStatus = () => {
    console.log('ScanViewer: getScanStatus: Begin');
    var result;
    console.log('ScanViewer: getScanStatus: calling isCompleted()');
    if (isCompleted()) {
      result = 'completed';
    } else if ((inProgressData?.inProgressScans?.length ?? 0) > 0) {
      result = 'in_progress';
    } else if ((pendingData?.workQueueScans?.length ?? 0) > 0) {
      result = 'queued';
    } else {
      result = 'not_found';
    }

    console.log('ScanViewer: getScanStatus: returning: ' + result);
    return result;
  };

  useEffect(() => {
    console.log('ScanViewer: useEffect: Begin');
    if (scanStatusCallback) {
      console.log('ScanViewer: useEffect: scanStatusCallback defined: calling getScanStatus()');
      let status = getScanStatus();
      console.log("ScanViewer: useEffect: calling scanStatusCallback('" + status + "')");
      scanStatusCallback(getScanStatus());
    }
    const interval = setInterval(() => {
      console.log('ScanViewer: useEffect: setInterval: calling getScanStatus()');
      getScanStatus();
      console.log('ScanViewer: useEffect: status is :' + scanStatus);
      if (scanStatus === 'not_found') {
        console.log('ScanViewer: useEffect: setInterval: calling pendingRefetch()');
        pendingRefetch();
        console.log('ScanViewer: useEffect: setInterval: calling inProgressRefetch()');
        inProgressRefetch();
        console.log('ScanViewer: useEffect: setInterval: calling completedRefetch()');
        completedRefetch();
      } else if (scanStatus === 'queued') {
        console.log('ScanViewer: useEffect: setInterval: calling inProgressRefetch()');
        inProgressRefetch();
        console.log('ScanViewer: useEffect: setInterval: calling completedRefetch()');
        completedRefetch();
      } else if (scanStatus === 'in_progress') {
        console.log('ScanViewer: useEffect: setInterval: calling completedRefetch()');
        completedRefetch();
      } else if (isCompleted()) {
        console.log('ScanViewer: useEffect: setInterval: calling clearInterval()');
        clearInterval(interval);
      }
    }, 5000);
    console.log('ScanViewer: useEffect: return: returning clearInterval()');
    return () => {
      console.log('ScanViewer: calling clearInterval()');
      clearInterval(interval);
    };
  });

  console.log('ScanViewer: calling getScanStatus()');
  scanStatus = getScanStatus();

  if (pendingLoading || completedLoading || inProgressLoading) {
    return <div />;
  }

  if (pendingError || completedError || inProgressError) {
    return (
      <div>
        Error!
        {pendingError?.message + ',' + completedError?.message + ',' + inProgressError?.message}
      </div>
    );
  }

  return (
    <div style={{ flex: 1, background: 'white', padding: '2rem' }}>
      <div className={styles.headingWrapper}>
        <Header as="h2">{getHeaderText()}</Header>
      </div>
      {scanStatus !== 'completed' && (
        <ul>
          <li>This display will be updated automatically.</li>
          <li>
            Scans take, on average, less than a minute, but may take a few minutes (or longer) to
            complete, depending on the size of the image.
          </li>
          <li>If scan remains in 'Scanning' state for more than 20 minutes, it may have failed.</li>
        </ul>
      )}
      <ScanStatusStepGroup scanStatus={scanStatus} />
      {scanStatus === 'completed' && (
        <p>
          Most Recent Scan was at{' '}
          {getLastScanTime()?.toDateString() + ', ' + getLastScanTime()?.toLocaleTimeString()}
        </p>
      )}
    </div>
  );
}
