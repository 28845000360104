import { AppFooter } from './components/AppFooter';
import { AppHeader } from './components/AppHeader';
import styles from './StandardAppLayout.module.css';

export function StandardAppLayout({ children }: { children?: React.ReactNode }) {
  return (
    <div className={styles.wrapper}>
      <AppHeader />
      <main className={styles.main}>{children ?? null}</main>
      <AppFooter />
    </div>
  );
}
