import { Container, Header } from 'semantic-ui-react';
import { ToasterProps, withToaster } from '../../components/ToastMessage';
import { ScanImage } from '../../components/ScanImage/ScanImage';
import styles from './ScanInfo.module.css';
import { ScanViewer } from '../../components/ScanViewer';
import { useState } from 'react';

export const ScanInfo = withToaster((props: ToasterProps) => {
  const [newImageName, setNewImageName] = useState<string>('');
  const [newVersion, setNewVersion] = useState<string>('');

  const handleNewScan = (imageName: string, version: string) => {
    setNewImageName(imageName);
    setNewVersion(version);
  };

  return (
    <>
      <div className={styles.images}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ flex: 1, background: 'white', padding: '2rem' }}>
            <div className={styles.headingWrapper}>
              <Header as="h1">Manage Scans</Header>
            </div>
            <Container>
              <ScanImage onScanAdded={handleNewScan} />
            </Container>
            {!!newVersion && !!newImageName && (
              <ScanViewer imageName={newImageName} version={newVersion} />
            )}
          </div>
        </div>
      </div>
    </>
  );
});
