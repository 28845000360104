import { Container } from 'semantic-ui-react';
import { IconLogo } from '../../../../components/IconLogo';
import styles from './AppFooter.module.css';

export function AppFooter() {
  return (
    <header className={styles.footer}>
      <Container>
        <div className={styles.content}>
          <div className={styles.iconAndCopyright}>
            <IconLogo width={20} />
            <span className={styles.copyright}>
              &copy; {new Date().getFullYear()}
              &nbsp; Qualia Labs, Inc. All Rights Reserved
            </span>
          </div>
          <div>
            <a
              rel="noreferrer"
              className={styles.link}
              href="https://www.qualia.com/connect-tos"
              target="_blank"
            >
              Terms of Service
            </a>
            <a
              rel="noreferrer"
              className={styles.link}
              href="https://www.qualia.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </Container>
    </header>
  );
}
