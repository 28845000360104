import { Button, Modal } from 'semantic-ui-react';
import { ScanImageFormControl } from '../../../components/ScanImage';
import React from 'react';

type Props = {
  onScanAdded: (imageName: string, version: string) => void;
  imageName: string | null;
};

export function AddScanModal({ imageName, onScanAdded }: Props) {
  const [open, setOpen] = React.useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const handleScanAdded = (imageName: string, version: string) => {
    closeModal();
    onScanAdded(imageName, version);
  };

  return (
    <Modal
      trigger={<Button>Scan New Version</Button>}
      open={open}
      onClose={closeModal}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>Add Your Resolution Item</Modal.Header>
      <Modal.Content>
        <ScanImageFormControl onScanAdded={handleScanAdded} imageName={imageName} />
      </Modal.Content>
      <Modal.Actions>
        <Button color="grey" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
