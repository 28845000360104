import { Button, Header, Icon, Message, SemanticCOLORS, Statistic } from 'semantic-ui-react';
import styles from './ImageVersion.module.css';
import { ScanViewer } from '../../components/ScanViewer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { ImageVersionVulns } from './components/ImageVersionVulns';
import { AddScanModal } from './components/AddScanModal';
import {
  VulnsQueryAsSummaries,
  VulnsQueryAsSummariesVariables,
  VulnsQueryAsSummaries_images_resolutions,
  VulnsQueryAsSummaries_images_versions,
  VulnsQueryAsSummaries_images_versions_langSummaries,
  VulnsQueryAsSummaries_images_versions_osSummaries,
  VulnsQueryAsSummaries_images_versions_scanSummary_openStats,
} from './__generated__/VulnsQueryAsSummaries';
import { ToasterProps, withToaster } from '../../components/ToastMessage';
import {
  SubmitScanMutation,
  SubmitScanMutationVariables,
} from '../../components/ScanImage/__generated__/SubmitScanMutation';
import { GQL_SCAN_IMAGE, GetVulnSuffix } from '../../components/ScanImage/ScanImage';

const GQL_GET_IMAGE_VERSION_DETAILS = gql`
  query VulnsQueryAsSummaries($imageName: ID, $version: ID) {
    images(filter: { imageName: $imageName, imageVersion: $version }) {
      imageName
      repository
      resolutions {
        id
        author
        owner
        status
        note
        scope
        acceptedAt
        expiresAt
      }
      versions {
        version
        scanSummary {
          openStats {
            totalVulns
            criticalVulns
            highVulns
            mediumVulns
            lowVulns
          }
        }
        langSummaries {
          severity
          package
          packagePath
          installedVersion
          fixedVersion
          status
          vulnerabilities {
            vulnerabilityID
            fixedVersion
            title
            description
            primaryURL
            severity
          }
        }
        osSummaries {
          severity
          package
          installedVersion
          fixedVersion
          status
          vulnerabilities {
            vulnerabilityID
            fixedVersion
            title
            description
            primaryURL
            severity
          }
        }
      }
    }
  }
`;

const getColor = (count: number | undefined, defaultColor: SemanticCOLORS): SemanticCOLORS => {
  if (count === 0) {
    return 'green';
  }
  return defaultColor;
};

export const ImageVersion = withToaster((props: ToasterProps) => {
  const [searchParams] = useSearchParams();
  const imageName = searchParams.get('imageName');
  const version = searchParams.get('tagName');
  const afterScanTimeIso = searchParams.get('afterTime');

  const [isScanCompleted, setScanCompleted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [afterScanTime, setAfterScanTime] = useState<Date | undefined>(
    !!afterScanTimeIso ? new Date(afterScanTimeIso) : undefined
  );

  const handleScanStatusReported = (scanStatus: string) => {
    console.log('ImageVersion: handleScanStatusReported: Begin, scanStatus:' + scanStatus);
    if (scanStatus === 'completed') {
      if (!isScanCompleted) {
        // console.log('ImageVersion: handleScanStatusReported: calling setScanCompleted(true)');
        setScanCompleted(true);
        // console.log('ImageVersion: handleScanStatusReported: calling refetch()');
        refetch();
      }
    } else {
      // console.log('ImageVersion: handleScanStatusReported: calling setScanCompleted(false)');
      setScanCompleted(false);
    }
    console.log('ImageVersion: handleScanStatusReported: returning');
  };

  const navigate = useNavigate();

  const handleNewScan = (imageName: string, version: string) => {
    navigate('/images/versions/details?imageName=' + imageName + '&tagName=' + version);
  };

  const handleRescan = () => {
    console.log('ImageVersion: handleRescan: Begin');
    console.log('ImageVersion: handleRescan: calling scanImage()');
    let success = scanImage();
    console.log('ImageVersion: handleRescan: scanImage() returned ' + success);
    if (success) {
      console.log('ImageVersion: handleRescan: calling setAfterScanTime(new Date())');
      setAfterScanTime(new Date());
      console.log('ImageVersion: handleRescan: calling setScanCompleted(false())');
      setScanCompleted(false);
    }
    console.log('ImageVersion: handleRescan: returning');
  };

  const [submitScanMutation, addScanMutationContext] = useMutation<
    SubmitScanMutation,
    SubmitScanMutationVariables
  >(GQL_SCAN_IMAGE);

  const getArtifactName = () => {
    return repo + '/' + imageName + ':' + version;
  };

  const scanImage = () => {
    console.log('ImageVersion: scanImage: Begin');
    try {
      let indexName = 'qv_vulns_' + GetVulnSuffix();
      console.log('ImageVersion: scanImage: calling submitScanMutation() using index:' + indexName);
      submitScanMutation({
        variables: {
          artifactName: getArtifactName(),
          clusterName: '',
          imageCount: 0,
          indexName: indexName,
          severities: 'UNKNOWN,LOW,MEDIUM,HIGH,CRITICAL',
        },
      });
    } catch (e: any) {
      console.log('ImageVersion: scanImage: submitScanMutation ERROR');
      console.log('ImageVersion: scanImage: calling setErrorMessage()');
      setErrorMessage(e.message);
      console.log(e.message);
      console.log('ImageVersion: scanImage: returning false');
      return false;
    }

    props.toaster.success(
      'Rescan Submitted',
      'Successfully submitted rescan of ' + imageName + ':' + version
    );
    console.log('ImageVersion: scanImage: returning true');
    return true;
  };

  const { loading, error, data, refetch } = useQuery<
    VulnsQueryAsSummaries,
    VulnsQueryAsSummariesVariables
  >(GQL_GET_IMAGE_VERSION_DETAILS, {
    variables: { imageName: imageName, version: version },
  });

  const isUserNotAuthenticatedError = (error: ApolloError | undefined): boolean => {
    return error?.message?.includes('401') ?? false;
  };

  if (isUserNotAuthenticatedError(error)) {
    window.location.href =
      '/login?returnTo=' +
      encodeURIComponent(
        window.location.pathname + '?imageName=' + imageName + '&tagName=' + version
      );
  }

  let repo: string = data?.images[0]?.repository ?? '';

  let imageVersion: VulnsQueryAsSummaries_images_versions | undefined;
  let osVulnSummaries: (VulnsQueryAsSummaries_images_versions_osSummaries | null)[] | null =
    Array<VulnsQueryAsSummaries_images_versions_osSummaries | null>(0);
  let langVulnSummaries: (VulnsQueryAsSummaries_images_versions_langSummaries | null)[] | null =
    Array<VulnsQueryAsSummaries_images_versions_langSummaries | null>(0);
  let stats: VulnsQueryAsSummaries_images_versions_scanSummary_openStats | null | undefined;
  let imageResolutions: VulnsQueryAsSummaries_images_resolutions[] | undefined | null;

  if (data && data.images[0] && data.images[0].versions && data.images[0].versions[0]) {
    imageVersion = data.images[0].versions[0];
    osVulnSummaries = imageVersion.osSummaries;
    langVulnSummaries = imageVersion.langSummaries;
    stats = imageVersion.scanSummary?.openStats;
    imageResolutions = data.images[0].resolutions;
  }

  return (
    <div className={styles.imageVersion} style={{ width: '100%' }}>
      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{ flex: 1, background: 'white', padding: '2rem 2rem 0rem 2rem' }}>
          {errorMessage && (
            <div className={styles.messagePlaceholder}>
              <Message negative className={styles.errorMessage}>
                <Icon name="bug" size="big" className="flat" />
                {errorMessage}
              </Message>
            </div>
          )}
          <div className={styles.headingWrapper}>
            <Header as="h1">Image Scan Results</Header>
            <Button onClick={() => navigate('/images/versions?imageName=' + imageName)}>
              Return to Versions
            </Button>
          </div>
          {!!imageName && !!version && (
            <ScanViewer
              afterScanTime={afterScanTime}
              imageName={imageName}
              version={version}
              scanStatusCallback={handleScanStatusReported}
            />
          )}
          {isScanCompleted && (
            <div style={{ flex: 1, background: 'white', padding: '0rem 2rem 2rem 2rem' }}>
              <div className={styles.headingWrapper}>
                <Header as="h2">Vulnerability Counts by Status</Header>
              </div>
              <div>
                <Statistic.Group>
                  <Statistic color={getColor(stats?.criticalVulns, 'red')}>
                    <Statistic.Value>{stats?.criticalVulns}</Statistic.Value>
                    <Statistic.Label>Criticals</Statistic.Label>
                  </Statistic>
                  <Statistic color={getColor(stats?.highVulns, 'orange')}>
                    <Statistic.Value>{stats?.highVulns}</Statistic.Value>
                    <Statistic.Label>Highs</Statistic.Label>
                  </Statistic>
                  <Statistic color={getColor(stats?.mediumVulns, 'green')}>
                    <Statistic.Value>{stats?.mediumVulns}</Statistic.Value>
                    <Statistic.Label>Mediums</Statistic.Label>
                  </Statistic>
                  <Statistic color={getColor(stats?.lowVulns, 'green')}>
                    <Statistic.Value>{stats?.lowVulns}</Statistic.Value>
                    <Statistic.Label>Lows</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </div>
              <div style={{ padding: '2rem' }}>
                <Button onClick={handleRescan}>Rescan This Version</Button>
                <AddScanModal onScanAdded={handleNewScan} imageName={imageName} />
              </div>
              {!!imageName && (
                <div>
                  <ImageVersionVulns
                    imageName={imageName}
                    imageVersion={imageVersion?.version ?? ''}
                    repo={repo}
                    toasterProps={props}
                    langVulnSummaries={langVulnSummaries}
                    osVulnSummaries={osVulnSummaries}
                    resolutions={imageResolutions}
                    refetchFunction={refetch}
                    messageAndScanFunction={async (rescan: boolean) => {
                      console.log('ImageVersion: messageAndScanFunction: Begin, rescan: ' + rescan);
                      props.toaster.success('Exception Added', 'Successfully added Exception');
                      if (rescan) {
                        console.log('ImageVersion: messageAndScanFunction: calling handleRescan:');
                        handleRescan();
                      }
                      console.log('ImageVersion: messageAndScanFunction: calling await refetch():');
                      await refetch();
                      console.log('ImageVersion: messageAndScanFunction: returning');
                    }}
                  />
                </div>
              )}
              {loading && <div />}
              {error && <div>Error! {error.message}</div>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
