import { gql, useMutation } from '@apollo/client';
import { Button, Form, FormField, Header, Icon, Input, Message } from 'semantic-ui-react';

import styles from './ScanImage.module.css';
import { useRef, useState } from 'react';
import {
  SubmitScanMutation,
  SubmitScanMutationVariables,
} from './__generated__/SubmitScanMutation';

export const GQL_SCAN_IMAGE = gql`
  mutation SubmitScanMutation(
    $artifactName: String!
    $indexName: String!
    $severities: String!
    $imageCount: Int!
    $clusterName: String!
  ) {
    pushImageScan(
      input: {
        artifactName: $artifactName
        indexName: $indexName
        severities: $severities
        imageCount: $imageCount
        clusterName: $clusterName
      }
    ) {
      artifactName
      imageName
      version
      repository
      indexName
      severities
      clusterName
      imageCount
    }
  }
`;

export function GetVulnSuffix(): string {
  let now = new Date();
  let result = `${now.getFullYear()}${now.getMonth()}${now.getDay()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;

  return result;
}

type Props = {
  onScanAdded: (imageName: string, version: string) => void;
  imageName?: string | null;
};

export function ScanImage({ onScanAdded, imageName }: Props) {
  const repoRef = useRef<Input>(null);
  const imageNameRef = useRef<Input>(null);
  const versionRef = useRef<Input>(null);

  const [repoInputVal, setRepoInputVal] = useState<string>(
    '761015926586.dkr.ecr.us-east-1.amazonaws.com'
  );
  const [imageNameInputVal, setImageNameInputVal] = useState<string>(imageName ?? '');
  const [versionInputVal, setVersionInputVal] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitScanMutation, mutationContext] = useMutation<
    SubmitScanMutation,
    SubmitScanMutationVariables
  >(GQL_SCAN_IMAGE);

  const getArtifactName = () => {
    return repoInputVal + '/' + imageNameInputVal + ':' + versionInputVal;
  };

  const validateForm = () => {
    return false;
  };

  return (
    <div className={styles.resolutions}>
      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{ flex: 1, background: 'white', padding: '2rem' }}>
          {errorMessage && (
            <div className={styles.messagePlaceholder}>
              <Message negative className={styles.errorMessage}>
                <Icon name="bug" size="big" className="flat" />
                {errorMessage}
              </Message>
            </div>
          )}
          <div className={styles.headingWrapper}>
            <Header as="h2">Submit New Scan</Header>
          </div>
          <Form>
            <Form.Group widths="equal">
              <FormField className={styles.formField}>
                <label>Repository</label>
                <Input
                  className={styles.input}
                  ref={repoRef}
                  defaultValue={repoInputVal}
                  onChange={(evt) => setRepoInputVal(evt.target.value)}
                />
              </FormField>
              <FormField className={styles.formField}>
                <label>Image</label>
                <Input
                  className={styles.input}
                  ref={imageNameRef}
                  defaultValue={imageNameInputVal}
                  onChange={(evt) => setImageNameInputVal(evt.target.value)}
                  placeholder="imageName"
                />
              </FormField>
              <FormField className={styles.formField}>
                <label>Version</label>
                <Input
                  className={styles.input}
                  ref={versionRef}
                  defaultValue={versionInputVal}
                  onChange={(evt) => setVersionInputVal(evt.target.value)}
                  placeholder="tag.0.1.2"
                />
              </FormField>
            </Form.Group>
            <FormField className={styles.formField}>
              <Button
                onClick={async () => {
                  try {
                    let indexName = 'qv_vulns_' + GetVulnSuffix();
                    console.log('using index:' + indexName);
                    await submitScanMutation({
                      variables: {
                        artifactName: getArtifactName(),
                        clusterName: '',
                        imageCount: 0,
                        indexName: indexName,
                        severities: 'UNKNOWN,LOW,MEDIUM,HIGH,CRITICAL',
                      },
                    });
                  } catch (e: any) {
                    setErrorMessage(e.message);
                    console.log(e.message);
                    return;
                  }
                  await onScanAdded(imageNameInputVal, versionInputVal);
                }}
                content="Submit Scan"
                labelPosition="right"
                icon="checkmark"
                positive
                disabled={validateForm()}
              />
            </FormField>
          </Form>
        </div>
      </div>
    </div>
  );
}
