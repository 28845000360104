import { gql, useQuery } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Header, Modal } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import styles from './ImageVersionViewer.module.css';
import {
  ImageVersionDetailsQuery,
  ImageVersionDetailsQueryVariables,
  ImageVersionDetailsQuery_images_versions,
} from './__generated__/ImageVersionDetailsQuery';
import {
  GridViewColumnDefinitions,
  GridViewTable,
} from '../../../../components/GridView/GridViewTable';
import { SortingFn, Row } from '@tanstack/react-table';

const GQL_GET_IMAGE_VERSION_DETAIL = gql`
  query ImageVersionDetailsQuery($imageName: ID) {
    images(filter: { imageName: $imageName }) {
      imageName
      repository
      versions {
        version
        locations
        scanSummary {
          openStats {
            totalVulns
            criticalVulns
            highVulns
            mediumVulns
            lowVulns
          }
          imageCreated
          scanHistory {
            scanTime
            cluster
            clusterCount
          }
        }
      }
    }
  }
`;

export function ImageVersionViewer() {
  const [searchParams] = useSearchParams();
  const imageName = searchParams.get('imageName');

  const { loading, error, data } = useQuery<
    ImageVersionDetailsQuery,
    ImageVersionDetailsQueryVariables
  >(GQL_GET_IMAGE_VERSION_DETAIL, {
    variables: { imageName: imageName + '' },
  });

  const closeModal = () => {
    console.log('In closeModal');
  };

  const navigate = useNavigate();

  const goToVersionDetails = (targetVersion: ImageVersionDetailsQuery_images_versions) =>
    navigate(
      '/images/versions/details?imageName=' + imageName + '&tagName=' + targetVersion.version
    );

  const getHeaderText = () => {
    if (!imageName) return 'Image Not Found';
    return 'Versions for image: ' + imageName;
  };

  const compareSemanticVersions = (a: string, b: string) => {
    console.log('a is:' + a + ', b is:' + b);
    // 1. Split the strings into their parts.
    const a1 = a.split('.');
    const b1 = b.split('.');
    // 2. Contingency in case there's a 4th or 5th version
    const len = Math.min(a1.length, b1.length);
    // 3. Look through each version number and compare.
    for (let i = 0; i < len; i++) {
      const a2 = +a1[i] || 0;
      const b2 = +b1[i] || 0;

      if (a2 !== b2) {
        return a2 > b2 ? 1 : -1;
      }
    }

    // 4. We hit this if the all checked versions so far are equal
    //
    return b1.length - a1.length;
  };

  const versionSortingFunction: SortingFn<ImageVersionDetailsQuery_images_versions | null> = (
    rowA: Row<ImageVersionDetailsQuery_images_versions | null>,
    rowB: Row<ImageVersionDetailsQuery_images_versions | null>,
    columnId: string
  ) => {
    if (rowA == null) return 1;
    if (rowB == null) return -1;
    const versionA = rowA.getValue(columnId) as string;
    const versionB = rowB.getValue(columnId) as string;

    return compareSemanticVersions(versionA, versionB);
  };

  const tableColumns: GridViewColumnDefinitions<ImageVersionDetailsQuery_images_versions | null>[] =
    [
      {
        id: uuid(),
        footer: (props) => props.column.id,
        columns: [
          {
            id: 'id',
            accessorFn: (row) => row?.version,
            header: 'Version',
            sortingFn: versionSortingFunction,
          },
          {
            id: 'scope',
            accessorFn: (row) => row?.scanSummary?.openStats?.totalVulns,
            header: '# Open Vulnerabilities',
          },
          {
            id: 'criticals',
            accessorFn: (row) => row?.scanSummary?.openStats?.criticalVulns,
            header: 'Criticals',
          },
          {
            id: 'highs',
            accessorFn: (row) => row?.scanSummary?.openStats?.highVulns,
            header: 'Highs',
          },
          {
            id: 'mediums',
            accessorFn: (row) => row?.scanSummary?.openStats?.mediumVulns,
            header: 'Mediums',
          },
          {
            id: 'lows',
            accessorFn: (row) => row?.scanSummary?.openStats?.lowVulns,
            header: 'Lows',
          },
          {
            id: 'viewDetails',
            size: 20,
            accessorFn: (row) => row,
            cell: (cell) => {
              return (
                <Button
                  onClick={() => {
                    goToVersionDetails(cell.getValue());
                  }}
                >
                  Vulnerability Detail
                </Button>
              );
            },
            header: '',
          },
        ],
      },
    ];

  if (loading) {
    return <div />;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <Modal open onClose={closeModal}>
      <Modal.Header>Image Versions</Modal.Header>
      <Modal.Content>
        <div className={styles.resolutions}>
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{ flex: 1, background: 'white', padding: '2rem' }}>
              <div className={styles.headingWrapper}>
                <Header as="h2">{getHeaderText()}</Header>
                <Button onClick={() => navigate('/images/')}>Return to Images</Button>
              </div>
              {(data?.images ? data.images.length : 0) > 0 && (
                <GridViewTable
                  style={{ width: '100%' }}
                  striped
                  sortable
                  pageSize={20}
                  data={data?.images[0].versions ?? []}
                  columns={tableColumns}
                />
              )}
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
