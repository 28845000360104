import { gql, useMutation, useQuery } from '@apollo/client';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { GridViewTable } from '../../components/GridView';
import { GridViewColumnDefinitions } from '../../components/GridView/GridViewTable';
import { AddResolution } from './components/AddResolution';
import { DeleteResolutionMutation } from './__generated__/DeleteResolutionMutation';
import {
  ResolutionQuery,
  ResolutionQueryVariables,
  ResolutionQuery_resolutions,
} from './__generated__/ResolutionQuery';

import styles from './Resolutions.module.css';
import { ToasterProps, withToaster } from '../../components/ToastMessage';

export const GQL_DELETE_RESOLUTION = gql`
  mutation DeleteResolutionMutation($id: ID!) {
    deleteResolution(input: { id: $id }) {
      id
      author
      owner
      status
      scope
    }
  }
`;

const GQL_GET_RESOLUTIONS = gql`
  query ResolutionQuery($artifactKeyParam: String) {
    resolutions(artifactKey: $artifactKeyParam) {
      id
      author
      owner
      status
      note
      scope
      acceptedAt
      expiresAt
    }
  }
`;

export const Resolutions = withToaster((props: ToasterProps) => {
  const [searchParams] = useSearchParams();
  const artifactKey = searchParams.get('artifactKey');
  const fromLocation = searchParams.get('from');

  const { loading, error, data, refetch } = useQuery<ResolutionQuery, ResolutionQueryVariables>(
    GQL_GET_RESOLUTIONS,
    {
      variables: { artifactKeyParam: artifactKey + '' },
    }
  );

  const navigate = useNavigate();

  const [deleteResolutionMutation] = useMutation<DeleteResolutionMutation>(GQL_DELETE_RESOLUTION);

  const getHeaderText = () => 'Resolutions for artifact: ' + artifactKey;

  const tableColumns: GridViewColumnDefinitions<ResolutionQuery_resolutions>[] = [
    {
      id: uuid(),
      footer: (props) => props.column.id,
      columns: [
        {
          id: 'id',
          accessorFn: (row) => row.id,
          header: 'ID',
        },
        {
          id: 'scope',
          accessorFn: (row) => row.scope,
          header: 'Scope',
        },
        {
          id: 'owner',
          accessorFn: (row) => row.owner,
          header: 'Owner',
        },
        {
          id: 'status',
          accessorFn: (row) => row.status,
          header: 'Status',
        },
        {
          id: 'note',
          accessorFn: (row) => row.note,
          header: 'Note',
        },
        {
          id: 'expiresAt',
          accessorFn: (row) => {
            if (row.expiresAt) {
              return new Date(row.expiresAt).toLocaleString();
            }
          },
          header: 'Expiration',
        },
        {
          id: 'created',
          accessorFn: (row) => {
            if (row.expiresAt) {
              return new Date(row.acceptedAt).toLocaleString();
            }
          },
          header: 'Created',
        },
        {
          id: 'author',
          accessorFn: (row) => row.author,
          header: 'Author',
        },
        {
          id: 'deleteResolution',
          size: 20,
          accessorFn: (row) => row.id,
          cell: (cell) => {
            return (
              <Button
                onClick={async () => {
                  try {
                    await deleteResolutionMutation({
                      variables: {
                        id: cell.getValue(),
                      },
                    });
                    refetch();
                  } catch (e: any) {
                    props.toaster.error('Error Deleting Resolution', e.message);
                    console.log(e.message);
                    refetch();
                    return;
                  }
                }}
              >
                Delete Resolution
              </Button>
            );
          },
          header: '',
        },
      ],
    },
  ];

  if (loading) {
    return <div />;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <div className={styles.resolutions}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ flex: 1, background: 'white', padding: '2rem' }}>
            <div className={styles.headingWrapper}>
              <Header as="h2">{getHeaderText()}</Header>
              <Button onClick={() => navigate('add?artifactKey=' + artifactKey)}>
                Add Resolution
              </Button>
              <Button
                onClick={() => {
                  if (fromLocation === 'projects') {
                    navigate('/projects');
                  } else navigate('/images');
                }}
              >
                Return
              </Button>
            </div>
            <GridViewTable
              style={{ width: '100%' }}
              striped
              data={data?.resolutions ?? []}
              columns={tableColumns}
            />
          </div>
        </div>
      </div>
      <Routes>
        <Route
          path="add"
          element={
            <AddResolution
              artifactKey={artifactKey}
              onResolutionAdded={async () => {
                await refetch();
                props.toaster.success('Resolution Created', 'Resolution Created Successfully');
              }}
            />
          }
        />
      </Routes>
    </>
  );
});
