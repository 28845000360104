import { gql, useQuery } from '@apollo/client';
import { Dropdown, Header } from 'semantic-ui-react';
import { Logo } from '../../../../components/Logo';
import { gqlCredentialStore } from '../../../../gqlClient';
import styles from './AppHeader.module.css';
import { ViewerQuery } from './__generated__/ViewerQuery';

export const GQL_GET_VIEWER = gql`
  query ViewerQuery {
    viewer {
      firstName
      lastName
      email
    }
  }
`;

const DropdownExampleDropdown = () => {
  const { data, client, loading } = useQuery<ViewerQuery>(GQL_GET_VIEWER);
  const name = loading ? '' : `${data?.viewer?.firstName} ${data?.viewer?.lastName}`;
  /* <Dropdown.Item icon="settings" text="Settings" />
     <Dropdown.Item icon="help" text="Help" /> */
  return (
    <Dropdown text={name}>
      <Dropdown.Menu>
        <Header>Account</Header>
        <Dropdown.Item
          icon="sign out"
          text="Logout"
          onClick={() => {
            gqlCredentialStore.clearToken();
            client.resetStore();
            window.location.href = '/logout';
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export function AppHeader() {
  return (
    <div className={styles.headerWrapper}>
      <header className={styles.header}>
        <div className={styles.content}>
          <Logo width={94} />
          <DropdownExampleDropdown />
        </div>
      </header>
    </div>
  );
}
