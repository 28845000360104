import React, { useState } from 'react';
import { ToastMessage, MessageTypes } from './ToastMessage';

interface ToasterFunc {
  /**
   * @param header The toast message title
   * @param message The toast message body
   * @param timeoutMS The number of milliseconds that the toast message should be visible.
   */
  (header: string, message: string, timeoutMS?: number): void;
}

interface Toaster {
  warning: ToasterFunc;
  error: ToasterFunc;
  info: ToasterFunc;
  success: ToasterFunc;
}

export interface ToasterProps {
  toaster: Toaster;
}

/**
 * Provides a given component the ability to trigger toast messages via the "toaster" property.
 * @param Component
 * @returns A higher order component wired with the toaster.
 */
export const withToaster = <P extends ToasterProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, 'toaster'>): JSX.Element => {
    const [type, setType] = useState<MessageTypes>();
    const [header, setHeader] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [timeoutMS, setTimeoutMS] = useState<number>();

    // Simple HOF to avoid repetition below.
    const makeToastFunc = (type: MessageTypes): ToasterFunc => {
      return (header, message, timeoutMS = 3_000) => {
        setType(type);
        setHeader(header);
        setMessage(message);
        setTimeoutMS(timeoutMS);
      };
    };

    const toaster: Toaster = {
      warning: makeToastFunc('warning'),
      error: makeToastFunc('error'),
      info: makeToastFunc('info'),
      success: makeToastFunc('success'),
    };

    return (
      <>
        {type && header && message ? (
          <ToastMessage
            type={type}
            header={header}
            message={message}
            timeoutMS={timeoutMS}
            onCleared={() => {
              setHeader('');
              setMessage('');
            }}
          />
        ) : null}
        <Component {...(props as P)} toaster={toaster} />
      </>
    );
  };
};
