import styles from './ToastMessage.module.css';
import { useEffect, useState, useRef } from 'react';
import invariant from 'invariant';

const messageTypes = ['info', 'warning', 'error', 'success'] as const;
export type MessageTypes = (typeof messageTypes)[number];

interface Props {
  type: MessageTypes;
  /**
   * Toast message body
   */
  message: string;
  /**
   * Toast message header
   */
  header: string;
  /**
   * The number of milliseconds that the toast message should be presented to the user.
   */
  timeoutMS?: number;
  /**
   * Called when the toast has been animated off the screen,
   * either by timeout or the user clicking on the message.
   */
  onCleared: () => void;
}

/**
 * The name of the animation that's triggered when the toast message is cleared
 */
export const ANIMATION_NAME_LEAVES = styles.leaves;

export const ToastMessage = (props: Props) => {
  const { type, message, header, timeoutMS = 3000, onCleared } = props;
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isHideAnimationComplete, setIsHideAnimationComplete] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const clearToastTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    clearToastTimeoutRef.current = setTimeout(() => {
      // nb: when visibility changes from true to false
      // the "hide" class gets applied to the outer div, which
      // in turn begins an animation to disappear the toast message.
      setIsVisible(false);
    }, timeoutMS);

    return () => {
      if (clearToastTimeoutRef.current) {
        clearTimeout(clearToastTimeoutRef.current);
      }
    };
  }, [timeoutMS]);

  // When the hide animation is complete the component is effectively dead.
  if (isHideAnimationComplete) {
    return null;
  }

  return (
    <div
      className={`${styles.container} ${styles[type]} ${!isVisible ? styles.hide : ''}`}
      ref={containerRef}
      data-testid="toastContainer"
      onAnimationEnd={(evt) => {
        if (evt.animationName !== ANIMATION_NAME_LEAVES) {
          return;
        }

        setIsHideAnimationComplete(true);
        // notify the parent component that the message has been cleared.
        onCleared();
      }}
      onClick={() => {
        invariant(containerRef.current, 'message container ref should not be null');

        setIsVisible(false);
      }}
    >
      <div className={styles.icon}></div>
      <div className={styles.textWrapper}>
        <div className={styles.messageHeader}>{header}</div>
        <div className={styles.messageBody}>{message}</div>
      </div>
    </div>
  );
};
