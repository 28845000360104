const originalWidth = 746.525;
const originalHeight = 674.555;
const ratio = originalHeight / originalWidth;

interface Props {
  width?: number;
  fill?: 'light' | 'dark';
}

export function IconLogo(props: Props) {
  const width = props.width ?? originalWidth;
  return (
    <svg
      xmlSpace="preserve"
      width={width}
      height={width * ratio}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="a">
          <path d="M0 607h607V0H0Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 -31.749 731.243)">
        <path
          d="M0 0h48.389C62.65 0 71.588-15.411 64.504-27.789L-135.2-376.724c-7.222-12.619-25.488-12.416-32.429.36l-138.017 254.055c-2.585 4.758.859 10.55 6.274 10.55h69.774a18.567 18.567 0 0 0 16.319-9.709l63.54-117.047L-19.834-11.502A22.852 22.852 0 0 0 0 0"
          style={{
            fill: '#6ec177',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(516.722 548.429)"
        />
        <path
          d="m0 0-190.163 349.562H66.064a18.567 18.567 0 0 1 16.123 9.359l35.054 61.368c2.72 4.761-.718 10.684-6.201 10.684l-406.708.002c-14.078 0-23.035-15.055-16.317-27.427L-57.458-65.233c6.939-12.781 25.212-12.984 32.434-.361L-.238-22.268A22.854 22.854 0 0 1 0 0"
          style={{
            fill: '#3d4043',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(338.073 117.457)"
        />
      </g>
    </svg>
  );
}
