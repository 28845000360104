import { Button, Header, Icon, Message, SemanticCOLORS, Statistic } from 'semantic-ui-react';
import styles from './ProjectScanViewer.module.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { ProjectScanVulns } from './components/ProjectScanVulns';
import { ToasterProps, withToaster } from '../../components/ToastMessage';
import {
  ProjVulnsQueryWithSummaries,
  ProjVulnsQueryWithSummariesVariables,
  ProjVulnsQueryWithSummaries_projects_pullRequests,
  ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan,
  ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries,
  ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_scanSummary_openStats,
  ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities,
  ProjVulnsQueryWithSummaries_projects_resolutions,
} from './__generated__/ProjVulnsQueryWithSummaries';

const GQL_GET_PROJECT_PR_DETAILS_QUERY = gql`
  query ProjVulnsQueryWithSummaries($projectName: ID, $pullRequestID: ID) {
    projects(filter: { projectName: $projectName, pullRequestID: $pullRequestID }) {
      applicationName
      repository
      resolutions {
        id
        author
        owner
        status
        note
        scope
        acceptedAt
        expiresAt
      }
      pullRequests {
        pullRequestId
        latestScan {
          version
          scanTime
          scanSummary {
            openStats {
              totalVulns
              criticalVulns
              highVulns
              mediumVulns
              lowVulns
            }
          }
          vulnerabilities {
            vulnerabilityID
            fixedVersion
            title
            target
            description
            primaryURL
            severity
            severity
            packageName
            packagePath
            installedVersion
            fixedVersion
            status
          }
          codeScanSummaries {
            target
            package
            status
            installedVersion
            fixedVersion
            severity
            vulnerabilities {
              vulnerabilityID
              fixedVersion
              title
              target
              description
              primaryURL
              severity
              severity
              packageName
              packagePath
              installedVersion
              fixedVersion
              status
              dataSourceID
            }
          }
        }
      }
    }
  }
`;

const getColor = (count: number | undefined, defaultColor: SemanticCOLORS): SemanticCOLORS => {
  if (count === 0) {
    return 'green';
  }
  return defaultColor;
};

const getScanTime = (
  scan: ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan | null
): Date | undefined => {
  var scanTime = new Date(scan?.scanTime);
  return scanTime;
};

export const ProjectScanViewer = withToaster((props: ToasterProps) => {
  const [searchParams] = useSearchParams();
  const projectName = searchParams.get('projectName');
  const pullRequestID = searchParams.get('pullRequestId');

  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery<
    ProjVulnsQueryWithSummaries,
    ProjVulnsQueryWithSummariesVariables
  >(GQL_GET_PROJECT_PR_DETAILS_QUERY, {
    variables: { projectName: projectName, pullRequestID: pullRequestID },
  });

  const getHeaderText = () => {
    if (!projectName) return 'Project Not Found';
    return 'Project Scan Results: ' + projectName + ' (' + pullRequestID + ')';
  };

  const isUserNotAuthenticatedError = (error: ApolloError | undefined): boolean => {
    return error?.message?.includes('401') ?? false;
  };

  if (isUserNotAuthenticatedError(error)) {
    window.location.href =
      '/login?returnTo=' +
      encodeURIComponent(
        window.location.pathname + '?projectName=' + projectName + '&pullRequestID=' + pullRequestID
      );
  }

  const repo: string = data?.projects[0]?.repository ?? '';
  const pullRequest: ProjVulnsQueryWithSummaries_projects_pullRequests | null =
    data?.projects &&
    data?.projects.length &&
    data?.projects[0].pullRequests &&
    data?.projects[0].pullRequests.length > 0
      ? data?.projects[0]?.pullRequests[0]
      : null;
  const scan: ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan | null =
    pullRequest?.latestScan ?? null;

  const vulns:
    | (ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities | null)[]
    | null = scan?.vulnerabilities ?? null;

  const codeScanVulnSummaries:
    | (ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_codeScanSummaries | null)[]
    | null = scan?.codeScanSummaries ?? null;

  const stats: ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_scanSummary_openStats | null =
    scan?.scanSummary?.openStats ?? null;
  const resolutions: ProjVulnsQueryWithSummaries_projects_resolutions[] | null =
    data?.projects[0].resolutions ?? null;

  if (data) {
    console.log('Got Data! Have ' + data.projects.length + ' elements');
    console.log('Have ' + stats?.criticalVulns + ' criticals.');
  }

  return (
    <div className={styles.projectScanViewer} style={{ width: '100%' }}>
      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{ flex: 1, background: 'white', padding: '2rem 2rem 0rem 2rem' }}>
          {errorMessage && (
            <div className={styles.messagePlaceholder}>
              <Message negative className={styles.errorMessage}>
                <Icon name="bug" size="big" className="flat" />
                {errorMessage}
              </Message>
            </div>
          )}
          <div className={styles.headingWrapper}>
            <Header as="h1">{getHeaderText()}</Header>
            <Button onClick={() => navigate('/projects/pullRequests?projectName=' + projectName)}>
              Return to Versions
            </Button>
          </div>

          {data && projectName && (
            <div style={{ flex: 1, background: 'white', padding: '0rem 2rem 2rem 2rem' }}>
              <div className={styles.headingWrapper}>
                <Header as="h2">Vulnerability Counts by Status</Header>
              </div>
              <div>
                <Statistic.Group>
                  <Statistic color={getColor(stats?.criticalVulns, 'red')}>
                    <Statistic.Value>{stats?.criticalVulns}</Statistic.Value>
                    <Statistic.Label>Criticals</Statistic.Label>
                  </Statistic>
                  <Statistic color={getColor(stats?.highVulns, 'orange')}>
                    <Statistic.Value>{stats?.highVulns}</Statistic.Value>
                    <Statistic.Label>Highs</Statistic.Label>
                  </Statistic>
                  <Statistic color={getColor(stats?.mediumVulns, 'green')}>
                    <Statistic.Value>{stats?.mediumVulns}</Statistic.Value>
                    <Statistic.Label>Mediums</Statistic.Label>
                  </Statistic>
                  <Statistic color={getColor(stats?.lowVulns, 'green')}>
                    <Statistic.Value>{stats?.lowVulns}</Statistic.Value>
                    <Statistic.Label>Lows</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </div>
              <p>
                <br />
                Most Recent Scan was at{' '}
                {getScanTime(scan)?.toDateString() + ', ' + getScanTime(scan)?.toLocaleTimeString()}
              </p>
              <div>
                <ProjectScanVulns
                  projectName={projectName}
                  codeScanvulns={codeScanVulnSummaries}
                  pullRequest={pullRequestID ?? ''}
                  repo={repo}
                  toasterProps={props}
                  vulnerabilities={vulns}
                  resolutions={resolutions}
                  refetchFunction={refetch}
                  messageFunction={(summary: string, message: string) => {
                    props.toaster.success(summary, message);
                  }}
                />
              </div>
            </div>
          )}
          {loading && <div />}
          {error && <div>Error! {error.message}</div>}
        </div>
      </div>
    </div>
  );
});
