import { ApolloError, gql, useQuery } from '@apollo/client';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Button, FormField, Header } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { GridViewTable } from '../../components/GridView';
import { GridViewColumnDefinitions } from '../../components/GridView/GridViewTable';
import { ImageQuery, ImageQueryVariables, ImageQuery_images } from './__generated__/ImageQuery';

import styles from './Images.module.css';
import React from 'react';
import { ImageVersionViewer } from './components/ImageVersionViewer';

const GQL_GET_IMAGES = gql`
  query ImageQuery($imageNameSearchParam: String) {
    images(filter: { imageNameSearch: $imageNameSearchParam }) {
      imageName
      repository
      versions {
        version
      }
      resolutions {
        id
      }
    }
  }
`;

interface Filters {
  imageNameSearch?: string;
}

function SideRail(props: { filters: Filters; onFiltersUpdate: (filters: Filters) => void }) {
  const [imageNameSearch, setImageNameSearch] = React.useState<string | null>(
    props.filters.imageNameSearch ?? null
  );

  return (
    <div className={styles.rail}>
      <FormField>
        <label htmlFor="name">Image Name Search*</label>
        <div className={`ui input ${styles.railInputWrapper}`}>
          <input
            value={imageNameSearch ?? ''}
            onChange={(evt) => setImageNameSearch(evt.target.value)}
            className={styles.railInput}
            id="name"
            autoComplete="off"
            type="text"
          />
        </div>
      </FormField>

      <Button
        className={styles.railButton}
        type="button"
        secondary
        onClick={() => {
          setImageNameSearch(null);
          props.onFiltersUpdate({});
        }}
      >
        Clear
      </Button>
      <Button
        className={styles.railButton}
        primary
        type="button"
        onClick={() => {
          const filters: Filters = {};
          filters.imageNameSearch = imageNameSearch ?? undefined;
          props.onFiltersUpdate(filters);
        }}
      >
        Search
      </Button>
      <div>
        <br />
        *searches with hyphens not currently supported.
      </div>
    </div>
  );
}

export function Images() {
  const [filters, setFilters] = React.useState<Filters>({});

  const { loading, error, data } = useQuery<ImageQuery, ImageQueryVariables>(GQL_GET_IMAGES, {
    variables: {
      imageNameSearchParam: filters.imageNameSearch ?? null,
    },
  });

  const navigate = useNavigate();

  const getResolutionText = (row: ImageQuery_images) => {
    if ((row.resolutions?.length ?? 0) === 0) {
      return 'No Risk Accepted.';
    } else {
      return 'Some Risk Accepted.';
    }
  };

  const handleDetailsClick = (targetImage: string) => navigate('versions?imageName=' + targetImage);
  const showResolutions = (targetImage: string) =>
    navigate('/images/resolutions?artifactKey=' + targetImage);

  const isUserNotAuthenticatedError = (error: ApolloError | undefined): boolean => {
    return error?.message?.includes('401') ?? false;
  };

  if (isUserNotAuthenticatedError(error)) {
    window.location.href = '/login?returnTo=' + encodeURIComponent(window.location.pathname);
  }

  const tableColumns: GridViewColumnDefinitions<ImageQuery_images>[] = [
    {
      id: uuid(),
      footer: (props) => props.column.id,
      columns: [
        {
          id: 'name',
          accessorFn: (row) => row.imageName,
          header: 'Image',
        },
        {
          id: 'versionCount',
          accessorFn: (row) => 'Found ' + row.versions?.length + ' versions.',
          header: 'Version Count',
        },
        {
          id: 'viewImageDetail',
          size: 20,
          accessorFn: (row) => row.imageName,
          cell: (cell) => {
            return (
              <Button onClick={() => handleDetailsClick(cell.getValue())}>
                View Image Details
              </Button>
            );
          },
          header: '',
        },
        {
          id: 'resolutionText',
          size: 20,
          accessorFn: (row) => row,
          cell: (cell) => {
            return getResolutionText(cell.getValue());
          },
          header: '',
        },
        {
          id: 'resolutionInfo',
          size: 20,
          accessorFn: (row) => row.imageName,
          cell: (cell) => {
            return (
              <Button onClick={() => showResolutions(cell.getValue())}>
                Manage Risk Exceptions
              </Button>
            );
          },
          header: '',
        },
      ],
    },
  ];

  if (loading) {
    return <div />;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <div className={styles.images}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ flex: 1, background: 'white', padding: '2rem' }}>
            <div className={styles.headingWrapper}>
              <Header as="h2">Your Images</Header>
            </div>
            <GridViewTable
              style={{ width: '100%' }}
              striped
              pageSize={50}
              data={data?.images ?? []}
              columns={tableColumns}
            />
          </div>
          <SideRail
            filters={filters}
            onFiltersUpdate={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>
      <Routes>
        <Route path="/versions" element={<ImageVersionViewer />} />
      </Routes>
    </>
  );
}
