import {
  ColumnDef,
  flexRender,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import styles from './GridViewTable.module.css';

export type GridViewColumnDefinitions<T> = ColumnDef<T>;

type Props<T> = {
  columns: GridViewColumnDefinitions<T>[];
  data: T[];
  tdClassName?: string;
  trClassName?: string;
  thClassName?: string;
  theadClassName?: string;
  tbodyClassName?: string;
  className?: string;
  striped?: boolean;
  sortable?: boolean;
  style?: React.CSSProperties;
  pageSize?: number;
};

export function GridViewTable<T>(props: Props<T>) {
  const [columns] = React.useState<GridViewColumnDefinitions<T>[]>(() => [
    ...(props.columns ?? []),
  ]);

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data: props.data,
    columns,
    state: props.sortable
      ? {
          sorting,
        }
      : {},
    onSortingChange: setSorting,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: props.pageSize ?? 10,
      },
    },
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  return (
    <>
      <table
        {...{
          style: {
            width: table.getCenterTotalSize(),
            ...(props.style ?? {}),
          },
        }}
        className={`${props.striped ? styles.striped : ''} ${styles.table} ${
          props.className ?? ''
        }`}
      >
        <thead className={`${styles.thead} ${props.theadClassName ?? ''}`}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={`${styles.tr ?? ''} ${props.thClassName ?? ''}`}>
              {headerGroup.headers.map((header) => (
                <th
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                      cursor: `${props.sortable && header.column.getCanSort() ? 'pointer' : ''}`,
                    },
                    className: `${styles.th} ${props.thClassName ?? ''}`,
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                  {header.isPlaceholder
                    ? null
                    : {
                        asc: (
                          <div className={`${styles.sortasc ?? ''}`}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        ),
                        desc: (
                          <div className={`${styles.sortdesc ?? ''}`}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                  <div
                    {...{
                      onMouseDown: header.getResizeHandler(),
                      onTouchStart: header.getResizeHandler(),
                      className: `${styles.resizer} ${
                        header.column.getIsResizing() ? styles.isResizing : ''
                      }`,
                    }}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={`${styles.tbody} ${props.tbodyClassName ?? ''}`}>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={`${styles.tr ?? ''} ${props.trClassName ?? ''}`}>
              {row.getVisibleCells().map((cell) => (
                <td
                  {...{
                    key: cell.id,
                    style: {
                      width: cell.column.getSize(),
                    },
                    className: `${styles.td} ${props.tdClassName ?? ''}`,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={`${styles.pagination}`}>
        <button
          className={styles.paginationButton}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon name="angle left" />
        </button>
        {table.getCanPreviousPage() && (
          <button className={styles.paginationButton} onClick={() => table.previousPage()}>
            {table.getState().pagination.pageIndex}
          </button>
        )}
        <button className={`${styles.currentPage} ${styles.paginationButton}`}>
          {table.getState().pagination.pageIndex + 1}
        </button>
        {table.getCanNextPage() && (
          <button className={styles.paginationButton} onClick={() => table.nextPage()}>
            {table.getState().pagination.pageIndex + 2}
          </button>
        )}
        <button
          className={styles.paginationButton}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <Icon name="angle right" />
        </button>
      </div>
    </>
  );
}
