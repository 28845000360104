import { Button, Modal } from 'semantic-ui-react';
import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  GridViewColumnDefinitions,
  GridViewTable,
} from '../../../../components/GridView/GridViewTable';
import { ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities } from '../../__generated__/ProjVulnsQueryWithSummaries';

type Props = {
  packageName: string;
  vulns: (ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities | null)[];
};

export function ProjVulnDetailModal({ vulns, packageName }: Props) {
  const [open, setOpen] = React.useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const vulnTableColumns: GridViewColumnDefinitions<ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities | null>[] =
    [
      {
        id: uuid(),
        footer: (props) => props.column.id,
        enableSorting: true,
        columns: [
          {
            id: 'vulnerabilityID',
            accessorFn: (row) => row?.vulnerabilityID,
            header: 'ID',
          },
          {
            id: 'severity',
            accessorFn: (row) => row?.severity,
            header: 'Severity',
          },
          {
            id: 'title',
            accessorFn: (row) => row?.title,
            header: 'Title',
          },
          {
            id: 'fixedVersion',
            accessorFn: (row) => row?.fixedVersion,
            header: 'Fixed Version',
          },
          {
            id: 'description',
            accessorFn: (row) => row?.description,
            header: 'Description',
          },
          {
            id: 'primaryURL',
            accessorFn: (row) => row?.primaryURL,
            header: 'Link',
          },
        ],
      },
    ];

  return (
    <Modal
      trigger={<Button>View Vulnerability Details</Button>}
      open={open}
      style={{ width: '90%' }}
      onClose={closeModal}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>Vulnerabilities for package {packageName}</Modal.Header>
      <Modal.Content>
        <GridViewTable
          striped
          sortable
          style={{ width: '100%' }}
          pageSize={200}
          data={vulns ?? []}
          columns={vulnTableColumns}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="grey" onClick={closeModal}>
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
