import { MouseEvent, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button, Checkbox, Step } from 'semantic-ui-react';
import { GridViewTable } from '../../../components/GridView';
import { GridViewColumnDefinitions } from '../../../components/GridView/GridViewTable';
import {
  VulnsQueryAsSummaries_images_resolutions,
  VulnsQueryAsSummaries_images_versions_langSummaries,
  VulnsQueryAsSummaries_images_versions_langSummaries_vulnerabilities,
  VulnsQueryAsSummaries_images_versions_osSummaries,
} from '../__generated__/VulnsQueryAsSummaries';
import styles from '../ImageVersion.module.css';
import { VulnDetailModal } from './VulnDetailModal';
import { OSVulnExceptionModal } from './OSVulnExceptionModal';
import { LangVulnExceptionModal } from './LangVulnExceptionModal';
import { ToasterProps } from '../../../components/ToastMessage';
import { useMutation } from '@apollo/client';
import { GQL_DELETE_RESOLUTION } from '../../Resolutions/Resolutions';
import { DeleteResolutionMutation } from '../../Resolutions/__generated__/DeleteResolutionMutation';

type Props = {
  osVulnSummaries: (VulnsQueryAsSummaries_images_versions_osSummaries | null)[] | null;
  langVulnSummaries: (VulnsQueryAsSummaries_images_versions_langSummaries | null)[] | null;
  resolutions: VulnsQueryAsSummaries_images_resolutions[] | undefined | null;
  imageName: string;
  imageVersion: string;
  repo: string;
  toasterProps: ToasterProps;
  messageAndScanFunction: (rescan: boolean, summary: string, message: string) => void;
  refetchFunction: () => void;
};

const getPackageName = (summary: VulnsQueryAsSummaries_images_versions_langSummaries): string => {
  return summary.package;
};

const getVulns = (
  summary: VulnsQueryAsSummaries_images_versions_langSummaries
): (VulnsQueryAsSummaries_images_versions_langSummaries_vulnerabilities | null)[] => {
  return summary.vulnerabilities;
};

const filterOsVulns = (
  vulns: (VulnsQueryAsSummaries_images_versions_osSummaries | null | undefined)[],
  filterOutLowSeverities: boolean
) => {
  return vulns.filter(
    (item: VulnsQueryAsSummaries_images_versions_osSummaries | null | undefined) => {
      if (
        filterOutLowSeverities &&
        (item?.severity === 'LOW' || item?.severity === 'MEDIUM' || item?.severity === 'UNKNOWN')
      ) {
        return false;
      }
      return item?.status === 'OPEN';
    }
  );
};

const filterLangVulns = (
  vulns: (VulnsQueryAsSummaries_images_versions_langSummaries | null | undefined)[],
  filterOutLowSeverities: boolean
) => {
  return vulns.filter(
    (item: VulnsQueryAsSummaries_images_versions_langSummaries | null | undefined) => {
      if (
        filterOutLowSeverities &&
        (item?.severity === 'LOW' || item?.severity === 'MEDIUM' || item?.severity === 'UNKNOWN')
      ) {
        return false;
      }
      return item?.status === 'OPEN';
    }
  );
};

const getScopeDisplayText = (key: string, id: string) => {
  const parts = id.split(':');
  if (key === 'CLASS') {
    if (parts[1] === 'CLASS') {
      return 'Entire Base Image';
    }
    return key;
  }
  if (key === 'VULN') {
    if (parts[1]) {
      return parts[1];
    }
    return 'Individual Vulnerability';
  }
};

const statusMap: { [status: string]: string } = {
  FIX_CAUSES_PROBLEMS: 'Fix Requires Substancial Refactoring',
  VULN_NOT_APPLICABLE: "Vulnerability Doesn't Apply",
  OTHER: 'Other',
  PATCHED_IMAGE_NA: 'Patched OS Image Not Available',
};

const getStatusDisplayText = (status: string) => {
  var resultMsg = statusMap[status];
  return resultMsg ?? status;
};

export function ImageVersionVulns({
  osVulnSummaries,
  langVulnSummaries,
  resolutions,
  refetchFunction,
  toasterProps: props,
  imageName,
  imageVersion,
  repo,
  messageAndScanFunction: messageFunction,
}: Props) {
  const SHOW_LANG_VULNS: number = 0;
  const SHOW_OS_VULNS: number = 1;
  const SHOW_RESOLUTIONS: number = 2;

  const [displayState, setDisplayState] = useState<number>(SHOW_LANG_VULNS);
  const [filterOutLowSeverities, setFilterOutLowSeverities] = useState<boolean>(true);

  let showLangVulns = (event?: MouseEvent) => {
    setDisplayState(SHOW_LANG_VULNS);
  };

  const onExceptionAdded = (rescan: boolean, summary: string, message: string) => {
    messageFunction(rescan, summary, message);
    console.log('in imageVersionVulns.onExceptionAdded, calling refetch');
    refetchFunction();
  };

  let showOSVulns = (event?: MouseEvent) => {
    setDisplayState(SHOW_OS_VULNS);
  };

  let showResolutions = (event?: MouseEvent) => {
    setDisplayState(SHOW_RESOLUTIONS);
  };

  const handleSeverityFilterToggle = (isChecked: boolean | undefined) => {
    console.log('In Severity Toggle:' + isChecked);
    setFilterOutLowSeverities(isChecked || false);
  };

  const osTableColumns: GridViewColumnDefinitions<
    VulnsQueryAsSummaries_images_versions_osSummaries | null | undefined
  >[] = [
    {
      id: uuid(),
      footer: (props) => props.column.id,
      enableSorting: true,
      columns: [
        {
          id: 'severity',
          accessorFn: (row) => row?.severity,
          header: 'Severity',
        },
        {
          id: 'package',
          accessorFn: (row) => row?.package,
          header: 'Package',
        },
        {
          id: 'installedVersion',
          accessorFn: (row) => row?.installedVersion,
          header: 'Installed Version',
        },
        {
          id: 'fixedVersion',
          accessorFn: (row) => row?.fixedVersion,
          header: 'Fixed Version',
        },
      ],
    },
  ];

  const langTableColumns: GridViewColumnDefinitions<
    VulnsQueryAsSummaries_images_versions_langSummaries | null | undefined
  >[] = [
    {
      id: uuid(),
      footer: (props) => props.column.id,
      enableSorting: true,
      columns: [
        {
          id: 'severity',
          accessorFn: (row) => row?.severity,
          header: 'Severity',
        },
        {
          id: 'package',
          accessorFn: (row) => row?.package,
          header: 'Package',
        },
        {
          id: 'installedVersion',
          accessorFn: (row) => row?.installedVersion,
          header: 'Installed Version',
        },
        {
          id: 'fixedVersion',
          accessorFn: (row) => row?.fixedVersion,
          header: 'Fixed Version',
        },
        {
          id: 'packagePath',
          accessorFn: (row) => row?.packagePath,
          header: 'Path',
        },
        {
          id: 'details',
          accessorFn: (row) => row,
          header: '',
          cell: (cell) => {
            return (
              <VulnDetailModal
                packageName={getPackageName(cell.getValue())}
                vulns={getVulns(cell.getValue())}
              ></VulnDetailModal>
            );
          },
        },
        {
          id: 'exception',
          accessorFn: (row) => row,
          header: '',
          cell: (cell) => {
            return (
              <LangVulnExceptionModal
                onExceptionAdded={messageFunction}
                vulnerabilities={getVulns(cell.getValue())}
                packageName={getPackageName(cell.getValue())}
                imageName={imageName}
              />
            );
          },
        },
      ],
    },
  ];

  const [deleteResolutionMutation] = useMutation<DeleteResolutionMutation>(GQL_DELETE_RESOLUTION);

  const resolutionTableColumns: GridViewColumnDefinitions<VulnsQueryAsSummaries_images_resolutions>[] =
    [
      {
        id: uuid(),
        footer: (props) => props.column.id,
        columns: [
          {
            id: 'scope',
            accessorFn: (row) => getScopeDisplayText(row.scope, row.id),
            header: 'Scope',
          },
          {
            id: 'owner',
            accessorFn: (row) => row.owner,
            header: 'Owner',
          },
          {
            id: 'status',
            accessorFn: (row) => getStatusDisplayText(row.status),
            header: 'Status',
          },
          {
            id: 'note',
            accessorFn: (row) => row.note,
            header: 'Note',
          },
          {
            id: 'expiresAt',
            accessorFn: (row) => {
              if (row.expiresAt) {
                return new Date(row.expiresAt).toLocaleString();
              }
            },
            header: 'Expiration',
          },
          {
            id: 'created',
            accessorFn: (row) => {
              if (row.expiresAt) {
                return new Date(row.acceptedAt).toLocaleString();
              }
            },
            header: 'Created',
          },
          {
            id: 'author',
            accessorFn: (row) => row.author,
            header: 'Author',
          },
          {
            id: 'deleteResolution',
            size: 20,
            accessorFn: (row) => row.id,
            cell: (cell) => {
              return (
                <Button
                  onClick={async () => {
                    try {
                      await deleteResolutionMutation({
                        variables: {
                          id: cell.getValue(),
                        },
                      });
                      refetchFunction();
                    } catch (e: any) {
                      props.toaster.error('Error Deleting Resolution', e.message);
                      console.log(e.message);
                      refetchFunction();
                      return;
                    }
                  }}
                >
                  Delete Resolution
                </Button>
              );
            },
            header: '',
          },
        ],
      },
    ];

  return (
    <div>
      <Step.Group attached="bottom">
        <Step
          active={displayState === SHOW_LANG_VULNS}
          icon="code"
          link
          onClick={showLangVulns}
          title="Language Vulnerabilities"
          description="Upgrade your code-based dependency versions to fix these vulnerabilities."
        />
        <Step
          active={displayState === SHOW_OS_VULNS}
          icon="file image"
          link
          onClick={showOSVulns}
          title="OS Vulnerabilities"
          description="Upgrade to a new version of your base image to fix these vulnerabilities."
        />
        <Step
          active={displayState === SHOW_RESOLUTIONS}
          icon="file image"
          link
          onClick={showResolutions}
          title="Suppressed Vulnerabilities"
          description="Some vulnerability fixes have been deferred."
        />
      </Step.Group>
      <div className={styles.imageVersionText}>
        <Checkbox
          toggle
          label="Show only Critical and High Vulns"
          checked={filterOutLowSeverities}
          onChange={(e, data) => handleSeverityFilterToggle(data.checked)}
        />
      </div>
      {displayState === SHOW_LANG_VULNS && (
        <GridViewTable
          style={{ width: '100%' }}
          striped
          sortable
          pageSize={200}
          data={filterLangVulns(langVulnSummaries ?? [], filterOutLowSeverities)}
          columns={langTableColumns}
        />
      )}
      {displayState === SHOW_OS_VULNS && (
        <div style={{ width: '100%' }}>
          <GridViewTable
            style={{ width: '100%', padding: '2rem' }}
            striped
            sortable
            pageSize={200}
            data={filterOsVulns(osVulnSummaries ?? [], filterOutLowSeverities)}
            columns={osTableColumns}
          />
          <p>
            If base image is upgraded to latest version, but fixes are still not available, you can
            can suppress these warnings for some period.
          </p>
          <OSVulnExceptionModal
            repo={repo}
            version={imageVersion}
            onExceptionAdded={onExceptionAdded}
            imageName={imageName}
          />
        </div>
      )}
      {displayState === SHOW_RESOLUTIONS && (
        <GridViewTable
          style={{ width: '100%' }}
          striped
          sortable
          pageSize={200}
          data={resolutions ?? []}
          columns={resolutionTableColumns}
        />
      )}
    </div>
  );
}
