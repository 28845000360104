import React from 'react';
import { Icon, Step } from 'semantic-ui-react';

type Props = {
  scanStatus: string;
};

function isActive(status: string, stage: string) {
  if (status === stage) {
    return true;
  } else return false;
}

function isDisabled(status: string, stage: string) {
  if (status === 'not_found') {
    return true;
  } else if (status === 'completed') {
    return false;
  } else if (status === 'queued' && stage !== 'queued') {
    return true;
  } else if (status === 'in_progress' && stage === 'completed') {
    return true;
  }
  return false;
}

const ScanStatusStepGroup = ({ scanStatus }: Props) => (
  <Step.Group>
    <Step active={isActive(scanStatus, 'queued')} disabled={isDisabled(scanStatus, 'queued')}>
      <Icon name="wait" />
      <Step.Content>
        <Step.Title>Queued</Step.Title>
        <Step.Description>Image is queued to be scanned</Step.Description>
      </Step.Content>
    </Step>

    <Step
      active={isActive(scanStatus, 'in_progress')}
      disabled={isDisabled(scanStatus, 'in_progress')}
    >
      <Icon name="magnify" />
      <Step.Content>
        <Step.Title>Scanning</Step.Title>
        <Step.Description>Image is being scanned</Step.Description>
      </Step.Content>
    </Step>

    <Step active={isActive(scanStatus, 'completed')} disabled={isDisabled(scanStatus, 'completed')}>
      <Icon name="thumbs up" />
      <Step.Content>
        <Step.Title>Complete</Step.Title>
        <Step.Description>Image scan is complete</Step.Description>
      </Step.Content>
    </Step>
  </Step.Group>
);

export default ScanStatusStepGroup;
