import { gql, useQuery } from '@apollo/client';
import { Button, Icon, Message } from 'semantic-ui-react';
import { Logo } from '../../components/Logo';

import styles from './Login.module.css';
import { ViewerEmailQuery } from './__generated__/ViewerEmailQuery';

const GQL_GET_VIEWER = gql`
  query ViewerEmailQuery {
    viewer {
      email
    }
  }
`;

export function Login() {
  const { data, loading, error } = useQuery<ViewerEmailQuery>(GQL_GET_VIEWER);
  const errorMessage = error ? `${error?.message}` : '';

  let submitDisabled: boolean = true;
  let loginFailed: boolean = false;

  if (data) {
    window.location.href = '/projects';
  }

  if (!loading) {
    submitDisabled = false;
  }

  return (
    <div className={styles.formWrapper}>
      <form className={styles.form} action="/login" method="get">
        <Logo className={styles.logo} fill="light" width={250} />
        <div className={styles.formActions}>
          <Button primary type="submit" disabled={submitDisabled} className={styles.loginButton}>
            Sign In With SSO
          </Button>
          <div className={styles.messagePlaceholder}>
            {loginFailed && (
              <Message negative className={styles.errorMessage}>
                <Icon name="bug" size="big" className="flat" />
                There was a problem logging in. Please check your credentials. {errorMessage}
              </Message>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
