import styles from './UnauthenticatedLayout.module.css';
import townImage from './town.png';

interface Props {
  children: React.ReactNode;
}

export function UnauthenticatedLayout({ children }: Props) {
  return (
    <div className={styles.page}>
      <main className={styles.main}>{children}</main>
      <footer className={styles.footer} style={{ backgroundImage: `url(${townImage})` }} />
    </div>
  );
}
