import { ApolloError, gql, useQuery } from '@apollo/client';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Button, FormField, Header } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { GridViewTable } from '../../components/GridView';
import { GridViewColumnDefinitions } from '../../components/GridView/GridViewTable';
import {
  ProjectsQuery,
  ProjectsQueryVariables,
  ProjectsQuery_projects,
} from './__generated__/ProjectsQuery';

import styles from './Projects.module.css';
import React from 'react';
import { PullRequestViewer } from './components/PullRequestViewer';

const GQL_GET_PROJECTS = gql`
  query ProjectsQuery($projectNameSearchParam: String) {
    projects(filter: { projectNameSearch: $projectNameSearchParam }) {
      applicationName
      repository
      pullRequests {
        pullRequestId
      }
      resolutions {
        id
      }
    }
  }
`;

interface Filters {
  projectNameSearch?: string;
}

function SideRail(props: { filters: Filters; onFiltersUpdate: (filters: Filters) => void }) {
  const [projectNameSearch, setProjectNameSearch] = React.useState<string | null>(
    props.filters.projectNameSearch ?? null
  );

  return (
    <div className={styles.rail}>
      <FormField>
        <label htmlFor="name">Project Name Search*</label>
        <div className={`ui input ${styles.railInputWrapper}`}>
          <input
            value={projectNameSearch ?? ''}
            onChange={(evt) => setProjectNameSearch(evt.target.value)}
            className={styles.railInput}
            id="name"
            autoComplete="off"
            type="text"
          />
        </div>
      </FormField>

      <Button
        className={styles.railButton}
        type="button"
        secondary
        onClick={() => {
          setProjectNameSearch(null);
          props.onFiltersUpdate({});
        }}
      >
        Clear
      </Button>
      <Button
        className={styles.railButton}
        primary
        type="button"
        onClick={() => {
          const filters: Filters = {};
          filters.projectNameSearch = projectNameSearch ?? undefined;
          props.onFiltersUpdate(filters);
        }}
      >
        Search
      </Button>
      <div>
        <br />
        *searches with hyphens not currently supported.
      </div>
    </div>
  );
}

export function Projects() {
  const [filters, setFilters] = React.useState<Filters>({});

  const { loading, error, data } = useQuery<ProjectsQuery, ProjectsQueryVariables>(
    GQL_GET_PROJECTS,
    {
      variables: {
        projectNameSearchParam: filters.projectNameSearch ?? null,
      },
    }
  );

  const navigate = useNavigate();

  const getResolutionText = (row: ProjectsQuery_projects) => {
    if ((row.resolutions?.length ?? 0) === 0) {
      return 'No Risk Accepted.';
    } else {
      return 'Some Risk Accepted.';
    }
  };

  const handleDetailsClick = (targetProject: string) =>
    navigate('pullRequests?projectName=' + targetProject);
  const showResolutions = (targetProject: string) =>
    navigate('resolutions?from=projects&artifactKey=' + targetProject);

  const isUserNotAuthenticatedError = (error: ApolloError | undefined): boolean => {
    return error?.message?.includes('401') ?? false;
  };

  if (isUserNotAuthenticatedError(error)) {
    window.location.href = '/login?returnTo=' + encodeURIComponent(window.location.pathname);
  }

  const tableColumns: GridViewColumnDefinitions<ProjectsQuery_projects>[] = [
    {
      id: uuid(),
      footer: (props) => props.column.id,
      columns: [
        {
          id: 'name',
          accessorFn: (row) => row.applicationName,
          header: 'Project Name',
        },
        {
          id: 'pullRequests',
          accessorFn: (row) => 'Found ' + row.pullRequests?.length + ' Pull Requests.',
          header: 'Pull Request Count',
        },
        {
          id: 'viewProjectDetail',
          size: 20,
          accessorFn: (row) => row.applicationName,
          cell: (cell) => {
            return (
              <Button onClick={() => handleDetailsClick(cell.getValue())}>
                View Project Details
              </Button>
            );
          },
          header: '',
        },
        {
          id: 'resolutionText',
          size: 20,
          accessorFn: (row) => row,
          cell: (cell) => {
            return getResolutionText(cell.getValue());
          },
          header: '',
        },
        {
          id: 'resolutionInfo',
          size: 20,
          accessorFn: (row) => row.applicationName,
          cell: (cell) => {
            return (
              <Button onClick={() => showResolutions(cell.getValue())}>
                Manage Risk Exceptions
              </Button>
            );
          },
          header: '',
        },
      ],
    },
  ];

  if (loading) {
    return <div />;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <div className={styles.projects}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ flex: 1, background: 'white', padding: '2rem' }}>
            <div className={styles.headingWrapper}>
              <Header as="h2">Projects</Header>
            </div>
            <GridViewTable
              style={{ width: '100%' }}
              striped
              pageSize={50}
              data={data?.projects ?? []}
              columns={tableColumns}
            />
          </div>
          <SideRail
            filters={filters}
            onFiltersUpdate={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>
      <Routes>
        <Route path="/pullRequests" element={<PullRequestViewer />} />
      </Routes>
    </>
  );
}
