import {
  Button,
  Checkbox,
  FormField,
  Icon,
  Input,
  List,
  Message,
  Modal,
  Radio,
  Select,
  TextArea,
} from 'semantic-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import styles from './ProjLangVulnExceptionModal.module.css';
import { GQL_GET_VIEWER } from '../../../../layouts/StandardAppLayout/components/AppHeader/AppHeader';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ViewerQuery } from '../../../../layouts/StandardAppLayout/components/AppHeader/__generated__/ViewerQuery';
import {
  CreateBulkResolutionsProject,
  CreateBulkResolutionsProjectVariables,
} from './__generated__/CreateBulkResolutionsProject';
import { ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities } from '../../__generated__/ProjVulnsQueryWithSummaries';

type Props = {
  projectName: string;
  packageName: string | undefined;
  vulnerabilities: (ProjVulnsQueryWithSummaries_projects_pullRequests_latestScan_vulnerabilities | null)[];
  onExceptionAdded: (summary: string, message: string) => void;
};

const GQL_PROJ_ADD_LANG_EXCEPTION = gql`
  mutation CreateBulkResolutionsProject(
    $projectName: String!
    $owner: String!
    $status: String!
    $note: String!
    $scope: String!
    $references: [String]!
    $expiresAt: Time!
  ) {
    createBulkResolutions(
      input: {
        imageName: $projectName
        owner: $owner
        status: $status
        note: $note
        scope: $scope
        references: $references
        expiresAt: $expiresAt
      }
    ) {
      id
      author
      owner
      status
      scope
      expiresAt
      acceptedAt
    }
  }
`;

export function ProjLangVulnExceptionModal({
  projectName,
  packageName,
  vulnerabilities,
  onExceptionAdded,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const { data: viewerData, loading: viewerLoading } = useQuery<ViewerQuery>(GQL_GET_VIEWER);
  const viewerEmail = viewerLoading ? '' : `${viewerData?.viewer?.email}`;

  const [selectedVulns, setSelectedVulns] = useState<Array<string>>([]);

  const noteRef = useRef<TextArea | null>(null);
  const ownerRef = useRef<Input | null>(null);

  const closeModal = () => {
    setNote('');
    setOwner(viewerEmail);
    setStatus('VULN_NOT_APPLICABLE');
    setExpiresTime(getTimeFromOffset('1y'));

    setErrorMessage('');
    setOpen(false);
  };

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [mutation, mutationContext] = useMutation<
    CreateBulkResolutionsProject,
    CreateBulkResolutionsProjectVariables
  >(GQL_PROJ_ADD_LANG_EXCEPTION);

  const [note, setNote] = useState<string>('');
  const [owner, setOwner] = useState<string>(viewerEmail);
  const [status, setStatus] = useState<string>('VULN_NOT_APPLICABLE');
  const [expiresString, setExpiresString] = useState<string>('1y');

  const getTimeFromOffset = (offset: string) => {
    let today = new Date();
    let returnDate = new Date();

    setExpiresString(offset);

    if (offset === '1wk') {
      returnDate.setDate(today.getDate() + 7);
    }
    if (offset === '2wk') {
      returnDate.setDate(today.getDate() + 14);
    }
    if (offset === '30d') {
      returnDate.setDate(today.getDate() + 30);
    }
    if (offset === '60d') {
      returnDate.setDate(today.getDate() + 60);
    }
    if (offset === '90d') {
      returnDate.setDate(today.getDate() + 90);
    }
    if (offset === '180d') {
      returnDate.setDate(today.getDate() + 180);
    }
    if (offset === '1y') {
      returnDate.setDate(today.getDate() + 365);
    }

    return returnDate;
  };

  const [expiresTime, setExpiresTime] = useState<Date>(new Date());

  useEffect(() => {
    setExpiresTime(getTimeFromOffset('1y'));
  }, []);

  const statusOptions = [
    {
      key: 'VulnNAKey',
      value: 'VULN_NOT_APPLICABLE',
      text: "Vulnerability Doesn't Apply",
    },
    {
      key: 'VulnProblemKey',
      value: 'FIX_CAUSES_PROBLEMS',
      text: 'Fix Requires Substancial Refactoring',
    },
    { key: 'VulnOtherKey', value: 'OTHER', text: 'Other' },
  ];

  const handleCheckBoxAction = (isChecked: boolean, cveid: string) => {
    let theVulns = selectedVulns;
    if (isChecked) {
      if (!selectedVulns?.includes(cveid)) {
        theVulns?.push(cveid);
      }
    } else {
      if (selectedVulns?.includes(cveid)) {
        let index = theVulns?.indexOf(cveid);
        theVulns?.splice(index ?? 0, 1);
      }
    }
    console.log('setting selectedVulns with length ' + theVulns.length);
    setSelectedVulns(theVulns);
  };

  return (
    <Modal
      trigger={
        <div style={{ padding: '1rem 1rem 2em 2rem' }}>
          <Button>Suppress Vulnerabilities</Button>
        </div>
      }
      open={open}
      onClose={closeModal}
      onOpen={() => {
        vulnerabilities.map((v) => handleCheckBoxAction(true, v?.vulnerabilityID ?? ''));
        setOpen(true);
      }}
    >
      <Modal.Header>Suppress Vulnerabilities for {packageName}</Modal.Header>
      <Modal.Content>
        <div className={styles.messagePlaceholder}>
          {errorMessage && (
            <Message negative className={styles.errorMessage}>
              <Icon name="bug" size="big" className="flat" />
              {errorMessage}
            </Message>
          )}
        </div>
        <div className={styles.modalContent}>
          <form className={styles.form}>
            <label>Selected Vulnerabilities</label>
            <FormField className={styles.formField}>
              <List>
                {vulnerabilities.map((v) => (
                  <List.Item as={'li'} key={v?.vulnerabilityID ?? 'VulnID Not Found'}>
                    <Checkbox
                      defaultChecked={true}
                      value={v?.vulnerabilityID ?? ''}
                      onChange={(evt, data) =>
                        handleCheckBoxAction(data.checked ?? false, v?.vulnerabilityID ?? '')
                      }
                    />
                    {v?.vulnerabilityID}: ({v?.severity}) {v?.title}
                  </List.Item>
                ))}
              </List>
            </FormField>
            <hr></hr>
            <FormField className={styles.formField}>
              <label>Status</label>
              <Select
                options={statusOptions}
                defaultValue={status}
                onChange={(evt, data) => setStatus(String(data.value))}
              />
            </FormField>
            <FormField className={styles.formField}>
              <label>Owner</label>
              <Input
                className={styles.input}
                ref={ownerRef}
                defaultValue={owner}
                onChange={(evt) => setOwner(evt.target.value)}
              />
            </FormField>
            <FormField className={styles.formField}>
              <label>Note</label>
              <TextArea
                className={styles.textArea}
                ref={noteRef}
                defaultValue={note}
                onChange={(evt) => setNote(evt.target.value)}
              />
            </FormField>
            <FormField className={styles.formField}>
              <label>Expires</label>
              <Radio
                label="1 Week"
                name="expiresGroup"
                value="1wk"
                checked={expiresString === '1wk'}
                onChange={(evt) => setExpiresTime(getTimeFromOffset('1wk'))}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Radio
                label="2 Weeks"
                name="expiresGroup"
                value="2wk"
                checked={expiresString === '2wk'}
                onChange={(evt) => setExpiresTime(getTimeFromOffset('2wk'))}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Radio
                label="1 Month"
                name="expiresGroup"
                value="30d"
                checked={expiresString === '30d'}
                onChange={(evt) => setExpiresTime(getTimeFromOffset('30d'))}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Radio
                label="2 Months"
                name="expiresGroup"
                value="60d"
                checked={expiresString === '60d'}
                onChange={(evt) => setExpiresTime(getTimeFromOffset('60d'))}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Radio
                label="3 Months"
                name="expiresGroup"
                value="90d"
                checked={expiresString === '90d'}
                onChange={(evt) => setExpiresTime(getTimeFromOffset('90d'))}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Radio
                label="6 Months"
                name="expiresGroup"
                value="180d"
                checked={expiresString === '180d'}
                onChange={(evt) => setExpiresTime(getTimeFromOffset('180d'))}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Radio
                label="1 Year"
                name="expiresGroup"
                value="1y"
                checked={expiresString === '1y'}
                onChange={(evt) => setExpiresTime(getTimeFromOffset('1y'))}
              />
            </FormField>
            <p style={{ textAlign: 'right' }}>
              NOTE: Results will not be visible in scan results until a new scan is executed.
            </p>
          </form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="grey" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              console.log('got selectedVulns of length ' + selectedVulns.length);
              await mutation({
                variables: {
                  references: selectedVulns ?? [],
                  projectName: projectName,
                  note: note,
                  owner: owner,
                  scope: 'VULN',
                  status: status,
                  expiresAt: expiresTime.toISOString(),
                },
              });
            } catch (e: any) {
              setErrorMessage(e.message);
              console.log(e.message);
              return;
            }
            await onExceptionAdded('Exception Added', 'Successfully added Exception(s)');
            closeModal();
          }}
          positive
          content="Save"
          labelPosition="right"
          icon="checkmark"
          disabled={note.trim().length === 0}
        />
      </Modal.Actions>
    </Modal>
  );
}
