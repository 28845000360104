interface Props {
  width?: number;
  fill?: 'light' | 'dark';
  className?: string;
}
const originalWidth = 1302.253;
const originalHeight = 389.515;
const ratio = originalHeight / originalWidth;

export const Logo = (props: Props) => {
  const width = props.width ?? originalWidth;
  const height = width * ratio;
  const fillColor = props.fill === 'light' ? '#ffffff' : '#000000';

  return (
    <svg
      xmlSpace="preserve"
      width={width}
      height={height}
      viewBox="0 0 1302.2534 389.51456"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ?? ''}
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="a">
          <path d="M0 606h1302V0H0Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 -217.241 599.658)">
        <path
          d="M0 0h27.941c8.236 0 13.396-8.899 9.306-16.047L-78.07-217.536c-4.17-7.286-14.718-7.169-18.726.208l-79.697 146.702c-1.492 2.747.497 6.092 3.624 6.092h40.29c3.932 0 7.547-2.152 9.423-5.607l36.691-67.587L-11.453-6.642A13.197 13.197 0 0 0 0 0"
          style={{
            fill: '#6ec177',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(447.556 449.742)"
        />
        <path
          d="m0 0-109.808 201.851H38.148c3.848 0 7.401 2.063 9.31 5.404L67.7 242.691c1.57 2.749-.415 6.169-3.581 6.169l-234.849.002c-8.129 0-13.301-8.694-9.422-15.838L-33.179-37.668c4.008-7.38 14.559-7.498 18.729-.208L-.138-12.858A13.193 13.193 0 0 1 0 0"
          style={{
            fill: fillColor,
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(344.397 200.881)"
        />
        <path
          d="M0 0c0-9.623 1.308-18.243 3.925-25.863 2.617-7.62 6.351-14.068 11.2-19.34 4.85-5.273 10.698-9.314 17.55-12.123 6.85-2.811 14.508-4.214 22.977-4.214 8.544 0 16.24 1.403 23.092 4.214 6.85 2.809 12.681 6.85 17.492 12.123 4.81 5.272 8.504 11.72 11.084 19.34 2.578 7.62 3.868 16.24 3.868 25.863 0 9.544-1.29 18.127-3.868 25.748-2.58 7.62-6.274 14.086-11.084 19.397-4.811 5.312-10.642 9.39-17.492 12.239-6.852 2.847-14.548 4.272-23.092 4.272-8.469 0-16.127-1.425-22.977-4.272-6.852-2.849-12.7-6.927-17.55-12.239-4.849-5.311-8.583-11.777-11.2-19.397C1.308 18.127 0 9.544 0 0m146.981-116.384h-22.168c-3.311 0-6.235.444-8.775 1.328-2.54.886-4.89 2.482-7.043 4.791L82.439-81.053c-8.314-2.54-17.244-3.81-26.787-3.81-12.394 0-23.689 2.115-33.888 6.35-10.2 4.232-18.955 10.103-26.267 17.608-7.314 7.505-12.972 16.453-16.973 26.844C-25.479-23.669-27.48-12.316-27.48 0c0 12.315 2.001 23.669 6.004 34.061 4.001 10.392 9.659 19.357 16.973 26.902 7.312 7.543 16.067 13.432 26.267 17.666 10.199 4.232 21.494 6.35 33.888 6.35 12.47 0 23.803-2.118 34.003-6.35 10.199-4.234 18.936-10.123 26.21-17.666 7.273-7.545 12.911-16.51 16.915-26.902 4.001-10.392 6.004-21.746 6.004-34.061 0-7.62-.771-14.876-2.309-21.764-1.541-6.89-3.773-13.318-6.697-19.282-2.927-5.966-6.506-11.431-10.738-16.395a73.594 73.594 0 0 0-14.433-12.99z"
          style={{
            fill: fillColor,
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(520.298 321.447)"
        />
        <path
          d="M0 0v-117.77h-15.01c-3.311 0-5.427 1.577-6.35 4.734l-1.848 10.969a71.556 71.556 0 0 0-7.793-7.159 50.594 50.594 0 0 0-8.718-5.542c-3.079-1.54-6.37-2.732-9.872-3.579-3.503-.846-7.255-1.27-11.257-1.27-6.465 0-12.163 1.079-17.088 3.233-4.927 2.154-9.064 5.196-12.412 9.121-3.349 3.926-5.871 8.64-7.563 14.144-1.694 5.503-2.54 11.564-2.54 18.185V0h24.824v-74.934c0-7.928 1.828-14.086 5.484-18.474 3.656-4.387 9.217-6.581 16.685-6.581 5.464 0 10.564 1.251 15.298 3.753 4.734 2.5 9.179 5.946 13.336 10.334V0Z"
          style={{
            fill: fillColor,
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(783.894 356.085)"
        />
        <path
          d="M0 0c-8.853-.309-16.32-1.021-22.399-2.136-6.082-1.116-11.009-2.54-14.78-4.272-3.772-1.732-6.485-3.792-8.139-6.177-1.657-2.387-2.483-5.005-2.483-7.851 0-2.772.442-5.138 1.328-7.102.884-1.962 2.116-3.579 3.695-4.849 1.576-1.27 3.424-2.194 5.542-2.771 2.116-.577 4.445-.866 6.985-.866 6.466 0 12.026 1.213 16.684 3.637C-8.911-29.962-4.388-26.44 0-21.822zm-65.813 49.879c13.316 12.392 29.172 18.589 47.57 18.589 6.772 0 12.796-1.097 18.07-3.29 5.271-2.194 9.698-5.294 13.277-9.295 3.58-4.003 6.311-8.757 8.198-14.259 1.885-5.505 2.829-11.604 2.829-18.301v-74.818H13.047c-2.387 0-4.196.364-5.427 1.097-1.232.73-2.271 2.211-3.117 4.445L2.078-36.37c-3.002-2.695-5.966-5.1-8.89-7.216a55.946 55.946 0 0 0-9.064-5.369c-3.118-1.463-6.466-2.558-10.045-3.291-3.579-.731-7.525-1.097-11.835-1.097-4.773 0-9.219.655-13.336 1.963-4.118 1.308-7.678 3.291-10.679 5.946-3.002 2.656-5.37 5.947-7.102 9.872-1.731 3.926-2.597 8.544-2.597 13.855 0 4.541 1.212 8.967 3.637 13.279 2.424 4.309 6.426 8.177 12.008 11.603 5.579 3.425 12.931 6.253 22.053 8.487 9.121 2.232 20.378 3.502 33.772 3.81v7.851c0 8.467-1.81 14.779-5.427 18.936-3.619 4.157-8.93 6.235-15.933 6.235-4.774 0-8.775-.598-12.008-1.79-3.234-1.194-6.044-2.482-8.429-3.868a157.096 157.096 0 0 1-6.35-3.868c-1.848-1.195-3.811-1.789-5.889-1.789-1.694 0-3.137.442-4.329 1.327a10.92 10.92 0 0 0-2.945 3.291z"
          style={{
            fill: fillColor,
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(879.378 289.81)"
        />
        <path
          d="M960.082 238.315h-24.824v170.882h24.824zM1018.965 238.315h-24.824v117.77h24.824zm4.157 153.332c0-2.233-.444-4.35-1.328-6.35a16.716 16.716 0 0 0-3.637-5.254 17.735 17.735 0 0 0-5.369-3.579c-2.041-.886-4.215-1.328-6.524-1.328-2.234 0-4.329.442-6.292 1.328a16.625 16.625 0 0 0-5.138 3.579c-1.464 1.501-2.638 3.251-3.522 5.254-.886 2-1.328 4.117-1.328 6.35 0 2.31.442 4.463 1.328 6.465.884 2.002 2.058 3.753 3.522 5.254a16.661 16.661 0 0 0 5.138 3.58c1.963.884 4.058 1.327 6.292 1.327 2.309 0 4.483-.443 6.524-1.327a17.775 17.775 0 0 0 5.369-3.58 16.727 16.727 0 0 0 3.637-5.254c.884-2.002 1.328-4.155 1.328-6.465"
          style={{
            fill: fillColor,
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M0 0c-8.854-.309-16.32-1.021-22.4-2.136-6.081-1.116-11.008-2.54-14.779-4.272-3.772-1.732-6.486-3.792-8.14-6.177-1.657-2.387-2.482-5.005-2.482-7.851 0-2.772.442-5.138 1.327-7.102.884-1.962 2.117-3.579 3.696-4.849 1.576-1.27 3.424-2.194 5.542-2.771 2.116-.577 4.445-.866 6.985-.866 6.466 0 12.025 1.213 16.684 3.637C-8.911-29.962-4.389-26.44 0-21.822zm-65.813 49.879c13.316 12.392 29.171 18.589 47.57 18.589 6.772 0 12.796-1.097 18.069-3.29 5.272-2.194 9.698-5.294 13.278-9.295 3.58-4.003 6.311-8.757 8.198-14.259 1.885-5.505 2.829-11.604 2.829-18.301v-74.818H13.047c-2.388 0-4.196.364-5.427 1.097-1.232.73-2.271 2.211-3.117 4.445L2.078-36.37c-3.002-2.695-5.967-5.1-8.89-7.216-2.927-2.118-5.947-3.908-9.064-5.369-3.118-1.463-6.467-2.558-10.046-3.291-3.579-.731-7.524-1.097-11.834-1.097-4.774 0-9.22.655-13.336 1.963-4.119 1.308-7.679 3.291-10.679 5.946-3.002 2.656-5.37 5.947-7.102 9.872-1.731 3.926-2.598 8.544-2.598 13.855 0 4.541 1.213 8.967 3.637 13.279 2.425 4.309 6.426 8.177 12.009 11.603 5.579 3.425 12.93 6.253 22.053 8.487 9.121 2.232 20.377 3.502 33.772 3.81v7.851c0 8.467-1.811 14.779-5.427 18.936-3.619 4.157-8.93 6.235-15.933 6.235-4.775 0-8.776-.598-12.008-1.79-3.234-1.194-6.044-2.482-8.43-3.868a156.31 156.31 0 0 1-6.349-3.868c-1.848-1.195-3.811-1.789-5.889-1.789-1.694 0-3.138.442-4.33 1.327a10.92 10.92 0 0 0-2.945 3.291z"
          style={{
            fill: fillColor,
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(1115.49 289.81)"
        />
      </g>
    </svg>
  );
};
