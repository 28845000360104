import { ApolloProvider } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import invariant from 'invariant';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getEnvironment } from './environment';
import { client } from './gqlClient';
import { StandardAppLayout } from './layouts/StandardAppLayout';
import { UnauthenticatedLayout } from './layouts/UnauthenticatedLayout';
import reportWebVitals from './reportWebVitals';
import { Login } from './routes/Login';
import { Images } from './routes/Images';

import './semantic-ui/semantic.less';
import { Resolutions } from './routes/Resolutions';
import { ScanInfo } from './routes/ScanInfo';
import { ImageVersion } from './routes/ImageVersion';
import { Projects } from './routes/Projects';
import { ProjectScanViewer } from './routes/ProjectScanViewer';

if (getEnvironment().bugsnag.enable && getEnvironment().bugsnag.token) {
  Bugsnag.start({
    apiKey: getEnvironment().bugsnag.token,
    appVersion: window.QUALIA_BUILD,
    plugins: [new BugsnagPluginReact()],
  });
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}) => (condition ? wrapper(children) : children);

const renderApplication = () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <ConditionalWrapper
      condition={getEnvironment().bugsnag.enable && !!getEnvironment().bugsnag.token}
      wrapper={(children: JSX.Element) => {
        const reactPlugin = Bugsnag.getPlugin('react');
        invariant(reactPlugin, 'Bugsnag yields a react plugin');

        const ErrorBoundary = reactPlugin.createErrorBoundary(React);
        return <ErrorBoundary>{children}</ErrorBoundary>;
      }}
    >
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <UnauthenticatedLayout>
                  <Login />
                </UnauthenticatedLayout>
              }
            />
            <Route
              path="/images/resolutions/*"
              element={
                <StandardAppLayout>
                  <Resolutions />
                </StandardAppLayout>
              }
            />
            <Route
              path="/projects/resolutions/*"
              element={
                <StandardAppLayout>
                  <Resolutions />
                </StandardAppLayout>
              }
            />
            <Route
              path="/images/versions/details"
              element={
                <StandardAppLayout>
                  <ImageVersion />
                </StandardAppLayout>
              }
            />
            <Route
              path="/images/*"
              element={
                <StandardAppLayout>
                  <Images />
                </StandardAppLayout>
              }
            />
            <Route
              path="/projects/pr/scan/*"
              element={
                <StandardAppLayout>
                  <ProjectScanViewer />
                </StandardAppLayout>
              }
            />
            <Route
              path="/projects/*"
              element={
                <StandardAppLayout>
                  <Projects />
                </StandardAppLayout>
              }
            />
            <Route
              path="/scans/new/*"
              element={
                <StandardAppLayout>
                  <ScanInfo />
                </StandardAppLayout>
              }
            />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </ConditionalWrapper>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};

const loader = document.getElementById('application-loader');
invariant(loader, 'the loader element was not present');

renderApplication();
setTimeout(() => {
  loader.addEventListener('transitionend', () => {
    loader.remove();
  });
  // setting the opacity to zero will trigger a new animation, thus triggering
  // the above callback.
  loader.style.opacity = '0';
  // the value here is the product of some visual spot checking.
}, 1250);
